.sd-user-hugpage {
    padding-top: 20px;
}
.sd-hug-info {
    .hug-flex-items {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        column-gap: 10px;
    }
}
.sd-hug-user-details {
    flex: 1;
    img{
        width: 36px;
        height: 36px;
        border-radius: 100%;
    }
    span {
        text-decoration: none;
        font-weight: 700;
        font-size: 16px;
        letter-spacing: -0.387234px;
        color: #990000;
        font-family: 'Exo', sans-serif;
        &.sd-background {
            background-color: #990000;
            color: #fff;
            padding: 12px 10px;
            width: 70px;
            text-align: center;
            flex-shrink: 0;
            &.sd-created-by {
                width: 100px;
            }
        }
    }
    & .sd-hug-user {
        &>div {
            display: flex;
            align-items: center;
            column-gap: 8px;
            background: #fff;
            margin-bottom: 20px;
            border-radius: 8px;
            overflow: hidden;
            padding-right: 10px;
            &.sd-to-hug {
                flex: 1;
            }
            &.full-width {
                flex: 1;
            }
        }
    }
}
.sd-hug-note{
    background: #FFFFFF;
    border-radius: 8px;
    padding: 10px;
    margin-bottom: 20px;
    h4{
        font-weight: 700;
        text-transform: capitalize;
        font-family: 'Roboto', sans-serif;
        margin-bottom: 10px;
    }
    p{
        font-family: 'Exo', sans-serif;
        font-weight: 700;
        text-align: justify;
        margin: 0;
        color: #000000;
    }
}
.sd-hug-reming-button {
    margin-top: 25px;
    button{
        width: 100%;
        max-width: 250px;
        svg{
            font-size: 20px;
            margin-right: 20px;
            color: #FFFFFF;
        }
    }
}
.sd-hug-card-content {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 800px;
    margin: auto;
    & img {
        width: 100%;
        border-radius: 25px;
        object-fit: cover;
    }
    & video {
        width: 100%;    
        cursor: pointer;
        border-radius: 25px;
    }
    & .play-icon {
        position: absolute;
        cursor: pointer;
    }
    & .play-icon-circle {
        height: 130px;
        width: 130px;
        background-color: #990000;
        color: #fff;
        border-radius: 100%;
        position: relative;
        margin: 0px auto;
        & svg {
            position: absolute;
            top: 0px;
            bottom: 0px;
            left: 0px;
            right: 0px;
            margin: auto;
            font-size: 70px;
            color: #fff;
        }
    }
}
.sd-hug-icons {
    display: flex;
    position: absolute;
    right: -160px;
    top: 15px;
    z-index: 1;
    flex-direction: column;
    row-gap: 15px;
}
.sd-hug-socialshare {
    a {
        background: #FFFFFF;
        border-radius: 8px;
        height: 40px;
        width: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #990000;
        transition: all ease-out .3s;
        &:hover{
            background-color: #990000;
            & svg{
                color:#fff;
            }
        }
        svg {
            font-size: 25px;
        }
    }
    .st-btn {
        display: none !important;
        min-width: auto !important;
        width: 100%;
        text-align: left !important;
        align-items: center;
        &.st-last {
            display: flex !important;
            background-color: #990000 !important;
            top: 0 !important;
            column-gap: 5px;
        }
        img {
            width: 25px !important;
            border-radius: 0px;
            height: auto !important;
            position: static !important;
        }
        .st-label {
            display: inline-block !important;
            padding: 0px !important;
            text-align: left;
            color: #fff !important;
            min-width: auto !important;
            font-weight: 400 !important;
        }
    }
}
.sd-hug-view-edit,
.sd-thank-email-icon,
.sd-email-icon,
.sd-hug-reminder-icon {
    display: flex;
    column-gap: 5px;
    background: #990000;
    border-radius: 8px;
    height: 40px;
    align-items: center;
    justify-content: flex-start;
    color: #fff !important;
    transition: all ease-out .3s;
    cursor: pointer;
    text-decoration: none;
    padding: 0px 10px;
    &:hover{
        background-color: #990000;
        opacity: 0.8;
        & svg{
            color:#fff;
        }
    }
    svg {
        color:#fff;
        font-size: 25px;
    }
}
.sd-hug-reminder-note p {
    margin-bottom: 0px;
    margin-top: 2px;
    max-width: 150px;
    font-size: 14px;
}
.sd-hug-not-found,
.sd-hug-loader {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding-top: 100px;
    &>span {
        font-size: 36px;
    }
}
@media only screen and (max-width: 1150px){
    .sd-hug-card-content {
        & .play-icon {
            display: none;
        }
    }
    .sd-hug-card-content {
        flex-direction: column-reverse;
    }
    .sd-hug-icons {
        position: static;
        margin-top: 10px;
        flex-direction: row;
        column-gap: 10px;
        flex-wrap: wrap;
    }
}
@media only screen and (max-width: 767px){
    .sd-hug-note{
        h4 {
            font-size: 16px;
        }
    } 
    .sd-hug-reming-button button{
        max-width: 230px;
        & svg{
            margin-right: 10px;
        }
    }
    .sd-hug-info {
        .hug-flex-items {
            display: block;
        }
    }
    .sd-hug-reminder-note p {
        color: #990000;
        text-shadow: none;
    }
    div .sd-hug-view-edit, div .sd-thank-email-icon, div .sd-email-icon, div .sd-hug-reminder-icon,
    .sd-hug-socialshare .st-btn .st-label {
        font-size: 14px;
    }
    div .sd-hug-view-edit svg, div .sd-thank-email-icon svg, div .sd-email-icon svg, div.sd-hug-reminder-icon svg {
        font-size: 20px;
    }
    .sd-hug-socialshare .st-btn {
        img {
            width: 20px !important;
        }
    }
}
@media only screen and (max-width: 580px){
    .sd-hug-info {
        &>div{
            width: 100%;
            justify-content: center;
        }
    }
    .sd-hug-card-content .play-icon-circle{
        height: 50px;
        width: 50px;
        svg{
            font-size: 30px;
        }
    }
    .sd-hug-not-found {
        &>span {
            font-size: 30px;
        }
    }
    div .sd-hug-view-edit, div .sd-thank-email-icon, div .sd-email-icon, div .sd-hug-reminder-icon,
    .sd-hug-socialshare .st-btn {
        padding: 0px 5px;
        column-gap: 3px;
    }
    .sd-hug-reminder-note p {
        max-width: 120px;
    }
    .sd-hug-socialshare .st-btn {
        padding: 0px 5px !important;
    }
}