.sd-upload-video-modal {
    & * {
        font-family: 'Roboto', sans-serif;
    }
    & .modal-header {
        text-align: right;
        & button {
            padding: 0px;
            box-shadow: none;
            background: transparent;
            position: absolute;
            right: 15px;
            top: 15px;
            & svg {
                font-size: 34px;
            }
        }
    }
    & .modal-title {
        font-weight: 700;
        font-size: 20px;
        line-height: 30px;
        letter-spacing: -0.387234px;
        margin-bottom: 23px;
        text-align: left;
    }
    & .MuiPaper-root {
        max-width: 840px;
        width: 100%;
        padding: 50px 38px 65px;
        display: block;
        border-radius: 8px;
    }
    & .MuiDialogTitle-root,
    & .MuiDialogContent-root,
    & .MuiDialogActions-root {
        padding: 0px;
        overflow: hidden;
    }
    & .sd-upload-video-file-input {
        display: flex;
        justify-content: space-between;
        align-items: center;
        column-gap: 12px;
        margin-bottom: 13px;
        & input {
            border: 1px solid;
            border-radius: 8px;
            padding: 11px 20px;
            line-height: 25px;
            letter-spacing: -0.387234px;
            width: 100%;
            &.input-File {
                display: none;
            }
        }
        & button.button {
            max-width: 135px;
            width: 100%;
        }
    }
    & .sd-upload-video-files-list {
        & p {
            font-size: 16px;
            line-height: 25px;
            letter-spacing: -0.387234px;
            margin-bottom: 0px;
        }
        & ul{
            & li {
                & p.file-name {
                    font-size: 26px;
                    line-height: 30px;
                    letter-spacing: -0.387234px;
                }
                &+li {
                    margin-top: 10px;
                }
            }
        }
    }
    & .modal-footer {
        display: flex;
        align-items: center;
        justify-content: space-between;
        column-gap: 32px;
        margin-top: 50px;
        & button.button {
            flex: 1;
        }
    }
}

@media only screen and (max-width: 991px){
    .sd-upload-video-modal {
        & .modal-title {
            font-size: 16px;
        }
        & .sd-upload-video-file-input {
            column-gap: 10px;
            & input {
                padding: 10px 15px;
                line-height: 24px;
            }
        }
        & .sd-upload-video-files-list {
            
            & ul{
                & li {
                    & p.file-name {
                        font-size: 20px;
                        line-height: 26px;
                    }
                }
            }
        }
        & .modal-footer {
            column-gap: 30px;
        }
    }
}

@media only screen and (max-width: 767px){
    .sd-upload-video-modal {
        & .MuiPaper-root {
            padding: 50px 20px 50px;
        }
        & .sd-upload-video-files-list {
            &  p {
                font-size: 18px;
                line-height: 24px;
            }
            & ul{
                & li {
                    & p.file-name {
                        font-size: 18px;
                        line-height: 24px;
                    }
                }
            }
        }
        & .modal-footer {
            column-gap: 10px;
        }
    }
}