.sd-recipient-modal {
    & * {
        font-family: 'Roboto', sans-serif;
    }
    & .modal-header {
        text-align: right;
        & button {
            padding: 0px;
            box-shadow: none;
            background: transparent;
            position: absolute;
            right: 15px;
            top: 15px;
            & svg {
                font-size: 34px;
            }
        }
    }
    & .modal-title {
        font-weight: 700;
        font-size: 20px;
        letter-spacing: -0.387234px;
        margin-bottom: 20px;
        text-align: center;
    }
    & .MuiPaper-root {
        max-width: 1250px;
        width: 100%;
        padding: 50px 45px 50px;
        display: block;
        border-radius: 8px;
    }
    & .MuiDialogTitle-root,
    & .MuiDialogContent-root,
    & .MuiDialogActions-root {
        overflow: hidden;
        padding: 0px;
    }
    & .sd-recipient-save {
        display: flex;
        align-items: center;
        justify-content: center;
        column-gap: 20px;
        & button.button {
            margin-top: 20px;
            max-width: 150px;
            width: 100%;
            & .sd_loader {
                & svg {
                    color: #fff;
                }
            }
        }
    }
}
.sd-recipient-modal-content{
    p{
        font-size: 18px;
        letter-spacing: -0.387234px;
        margin-bottom: 25px;
    }
    & .sd-step-one-inputs {
        padding: 1px;
    }
    & .element-container {
        &.sd-file-checkmark {
            width: auto;
            & input:checked + label::after {
                top: -10px;
            }
        }
        &.sd-email-sent-detail {
            width: 100%;
            max-width: max-content;
            & svg {
                position: static;
                fill: green;
            }
            & p {
                font-size: 12px;
                display: block;
            }
        }
    }
}
.sd-recipient-error-message {
    margin-bottom: 20px;
}