.sd-step5-note {
    background: #FFFFFF;
    border-radius: 33px;
    padding: 25px 15px 40px;
    text-align: center;
    & img {
        max-width: 120px;
        width: 100%;
        margin-bottom: 10px;
    }
    & h1 {
        font-size: 30px;
        margin-bottom: 10px;
    }
    & p {
        font-size: 20px;
        line-height: 40px;
        margin-bottom: 0px;
        &.small {
            font-size: 17px;
            line-height: 25px;
            font-weight: 300;
            & span {
                font-weight: 400;
                text-decoration: underline;
                &.bold {
                    font-weight: 700;
                    text-decoration: none;
                }
            }
        }
        &.audio-note {
            font-size: 13px;
            line-height: 15px;
            letter-spacing: 1.11277px;
            text-decoration-line: underline;
            margin-bottom: 0px;
            cursor: pointer;
        }
    }
    & button.button {
        margin-top: 30px;
        max-width: 250px;
        width: 100%;
        display: block;
        margin-right: auto;
        margin-left: auto;
    }
}

@media only screen and (max-width: 767px){
    .sd-step5-note {
        & h1 {
            font-size: 25px;
            margin-bottom: 0;
        }
        & p {
            font-size: 17px;
            &.small {
                font-size: 16px;
                margin-top: 5px;
            }
        }
    }
}