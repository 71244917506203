.sd-step4-note {
    & h3 {
        margin-bottom: 10px;
    }
    & p {
        margin-bottom: 0px;
        & span {
            font-weight: bold;
        }
    }
}