.sd-inspirational-message-modal {
    & * {
        font-family: 'Roboto', sans-serif;
    }
    & .modal-header {
        text-align: right;
        & button {
            padding: 0px;
            box-shadow: none;
            background: transparent;
            position: absolute;
            right: 15px;
            top: 15px;
            & svg {
                font-size: 34px;
            }
        }
    }
    & .modal-title {
        font-weight: normal;
        font-size: 22px;
        letter-spacing: -0.387234px;
        margin-bottom: 20px;
        text-align: left;
    }
    & .MuiPaper-root {
        max-width: 840px;
        width: 100%;
        padding: 50px;
        display: block;
        border-radius: 8px;
        &.MuiAccordion-root {
            padding: 0px;
            margin: 10px 0px;
            box-shadow: none;
            & .MuiAccordionSummary-root {
                min-height: auto;
                background-color: #990000;
                color: #fff;
                border-radius: 5px;
            }
            & .MuiAccordionSummary-content {
                margin: 10px 0px;
                &.Mui-expanded {
                    margin: 10px 0px;
                }
                & p {
                    margin-bottom: 0px;
                    color: #fff;
                }
            }
            & .MuiAccordionSummary-expandIconWrapper {
                & svg {
                    color: #fff;
                    fill: #fff;
                }
            }
        }
    }
    & .MuiDialogTitle-root,
    & .MuiDialogContent-root,
    & .MuiDialogActions-root {
        padding: 0px;
        overflow: hidden;
    }
    & .modal-footer {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 25px;
        column-gap: 10px;
        & button.button {
            max-width: 156px;
            width: 100%;
        }
    }
    & ul {
        list-style-type: disc;
        padding-left: 20px
    }
}

@media only screen and (max-width: 991px){
    .sd-inspirational-message-modal {
        & .modal-title {
            font-size: 16px;
        }
        & .MuiPaper-root {
            padding: 50px 20px;
        }
    }
}