.sd-home-content-video-section {
    display: flex;
    width: 100%;
    column-gap: 40px;
    align-items: center;
    justify-content: space-between;
    padding-top: 10px;
    &>div {
        flex: 1;
    }
    & h1 {
        max-width: 350px;
        margin-bottom: 25px;
        line-height: 65px;
    }
    & p {
        font-size: 22px;
        line-height: 28px;
        margin: 0px;
    }
}
.sd-video-btn {
    display: flex;
    column-gap: 40px;
    align-items: center;
    margin-top: 44px;
    margin-bottom: 22px;
    & button.button {
        box-shadow: 0px 20px 30px rgba(3, 15, 26, 0.2);
        text-transform: capitalize;
        font-size: 18px;
    }
    & p {
        margin-bottom: 0px;
        font-size: 22px;
        line-height: 22px;
        display: flex;
        justify-content: center;
        column-gap: 10px;
        cursor: pointer;
    }
}
.sd-home-video-hug-section {
    & >p {
        margin-top: 10px;
        text-align: center;
    }
}
.sd-home-video-section {
    width: 100%;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    & img {
        max-width: 600px;
        width: 100%;
        border-radius: 12px;
    }
    & .play-icon {
        position: absolute;
        cursor: pointer;
    }
    & .play-icon-circle {
        background-color: #990000;
        color: #fff;
        border-radius: 100%;
        width: 100px;
        height: 100px;
        position: relative;
        margin: 0px auto;
        margin-top: 150px;
        & svg {
            position: absolute;
            color: #fff;
            top: 0px;
            bottom: 0px;
            left: 0px;
            right: 0px;
            margin: auto;
            width: 80px;
            height: 80px;
        }
    }
}
.sd-home-mouse-section {
    margin-top: 32px;
    text-align: center;
    & .mouse-icon-circle {
        width: 95px;
        height: 95px;
        border-radius: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: auto;
        background-color: #fff;
        z-index: 1;
        position: relative;
    }
    & .mouse-icon {
        width: 22px;
        height: 45px;
        border: 2px solid;
        border-radius: 60px;
        position: relative;
        &::before {
            content: '';
            width: 6px;
            height: 6px;
            position: absolute;
            top: 5px;
            left: 50%;
            transform: translateX(-50%);
            background-color: #333;
            border-radius: 50%;
            opacity: 1;
            animation: wheel 2s infinite;
            -webkit-animation: wheel 2s infinite;
        }
    }
}
@keyframes wheel {
	to {
		opacity: 0;
		top: 35px;
	}
}
@-webkit-keyframes wheel {
	to {
		opacity: 0;
		top: 35px;
	}
}

.sd-home-features-section {
    background-color: rgba(153, 0, 0, 0.05);
    padding: 92px 0px;
    margin-top: -47px;
    & h1 {
        line-height: 40px;
    }
    & .sd-features-lists {
        display: flex;
        justify-content: flex-start;
        column-gap: 2%;
        row-gap: 50px;
        flex-wrap: wrap;
        &>div {
            max-width: 23%;
            width: 100%;
        }
        & p {
            margin-bottom: 0px;
            padding-right: 25px;
            &.full-widht {
                padding-right: 0px;
            }
            span {
                font-weight: bold;
            }
        }
        & h4 {
            margin-bottom: 10px;
        }
        & img {
            display: block;
            margin-bottom: 5px;
            width: auto;
            height: 50px;
        }
    }
}
.sd-video-modal-content iframe{
        width: 100%;
        height: 500px;
        display: block;
}
@media only screen and (max-width: 1200px){
    .sd-home-content-video-section {
        & h3 {
            font-size: 60px;
            line-height: 70px;
        }
    }
    .sd-home-features-section {
        & .sd-features-lists {
            &>div {
                max-width: 32%;
            }
        }
    }
    .sd-video-modal-content iframe{
        height: 400px;
    }
}
@media only screen and (max-width: 991px) {
    .sd-home-content-video-section {
        & h3 {
            font-size: 50px;
            line-height: 60px;
        }
    }
    .sd-video-btn {
        flex-direction: column;
        row-gap: 20px;
        align-items: flex-start;
        & button.button{
            padding: 15px 25px;
        }
    }
    .sd-home-features-section h1{
        line-height: 30px;
    }
    .sd-home-video-section {
        & .play-icon-circle {
            width: 90px;
            height: 90px;
            margin-top: 130px;
            & svg {
                width: 70px;
                height: 70px;
            }
        }
    }
}
@media only screen and (max-width: 850px) {
    .sd-home-content-video-section {
        flex-direction: column;
        row-gap: 30px;
    }
    .sd-video-btn {
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        column-gap: 20px;
    }
    .sd-home-features-section {
        & .sd-features-lists {
            column-gap: 4%;
            &>div {
                max-width: 48%;
            }
            & p {
                padding-right: 0px;
            }
        }
    }
    .sd-video-modal-content iframe {
        height: 300px;
    }
}
@media only screen and (max-width: 560px) {
    .sd-home-features-section {
        & .sd-features-lists {
            column-gap: 0px;
            row-gap: 30px;
            &>div {
                max-width: 100%;
            }
        }
    }
    .sd-video-modal-content iframe {
        height: 250px;
    }
}
@media only screen and (max-width: 480px) {
    .sd-video-btn {
        flex-direction: column;
        row-gap: 20px;
        align-items: flex-start;
    }
    .sd-home-video-section {
        & .play-icon-circle {
            width: 70px;
            height: 70px;
            margin-top: 100px;
            & svg {
                width: 60px;
                height: 60px;
            }
        }
    }
}