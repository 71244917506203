.sd-video-modal {
    & * {
        font-family: 'Roboto', sans-serif;
    }
    & .modal-header {
        text-align: right;
        & button {
            padding: 0px;
            box-shadow: none;
            background: transparent;
            position: absolute;
            right: 15px;
            top: 15px;
            & svg {
                font-size: 34px;
            }
        }
    }
    & .modal-title {
        font-weight: 700;
        font-size: 29px;
        line-height: 34px;
        letter-spacing: -0.387234px;
        margin-bottom: 23px;
        text-align: left;
    }
    & .MuiPaper-root {
        max-width: 840px;
        width: 100%;
        padding: 40px;
        display: block;
        border-radius: 8px;
    }
    & .MuiDialogTitle-root,
    & .MuiDialogContent-root,
    & .MuiDialogActions-root {
        padding: 0px;
        overflow: hidden;
    }
    & .sd-audio-modal-icons {
        display: flex;
        justify-content: center;
        align-items: center;
        column-gap: 40px;
        margin-top: 26px;
        &>p {
            margin-bottom: 0px;
            font-weight: 700;
            font-size: 35px;
            text-align: center;
            letter-spacing: -0.387234px;
        }
        & div {
            & p {
                font-weight: 700;
                font-size: 16px;
                line-height: 22px;
                text-align: center;
                letter-spacing: -0.387234px;
            }
        }
        & .sd-icon {
            background: linear-gradient(233.87deg, #FFFFFF 2.19%, rgba(255, 255, 255, 0.19) 99.96%);
            box-shadow: 0px 0px 10px #00000040;
            border-radius: 8px;
            width: 130px;
            height: 130px;
            display: flex;
            align-items: center;
            justify-content: center;
            margin: auto auto 20px auto;
            cursor: pointer;
            & svg {
                font-size: 80px;
            }
        }
    }
}

@media only screen and (max-width: 991px){
    .sd-video-modal {
        & .sd-audio-modal-icons {
            &>p {
                font-size: 40px;
                line-height: 50px;
            }
            & div {
                & p {
                    font-size: 18px;
                    margin-bottom: 0px;
                }
            }
            & .sd-icon {
                width: 120px;
                height: 120px;
                & svg {
                    font-size: 50px;
                }
            }
        }
    }
}
@media only screen and (max-width: 767px){
    .sd-video-modal {
        & .MuiPaper-root {
            padding: 50px 20px 50px;
        }
        & .sd-audio-modal-icons {
            column-gap: 20px;
            &>p {
                font-size: 30px;
                line-height: 40px;
            }
            & .sd-icon {
                width: 100px;
                height: 100px;
                & svg {
                    font-size: 60px;
                }
            }
        }
    }
}
@media only screen and (max-width: 480px){

    .sd-video-modal {
        & .MuiPaper-root {
            padding: 40px 20px 40px;
        }
        & .sd-audio-modal-icons {
            column-gap: 15px;
            &>p{
                font-size: 24px;
            }
            & .sd-icon {
                width: 60px;
                height: 60px;
                margin: auto;
                & svg {
                    font-size: 40px;
                }
            }
        }
    }

}