.sd-email-unsubscribe {
    text-align: center;
    padding-top: 80px;
    padding-bottom: 60px;
    & svg {
        font-size: 100px;
    }
    & h2 {
        margin-bottom: 10px;
    }
    & button.button {
        font-size: 20px;
        line-height: 27px;
        text-align: center;
        letter-spacing: -0.387234px;
        text-transform: uppercase;
        max-width: 180px;
        width: 100%;
        padding: 15px 10px;
        border-radius: 8px;
        margin-right: auto;
        margin-left: auto;
        & svg {
            color: #fff;
        }
    }
    & ul {
        list-style-type: none;
        display: flex;
        flex-direction: column;
        row-gap: 15px;
        max-width: 450px;
        margin: 40px auto 80px;
        align-items: flex-start;
        input{
            padding: 0;
            height: initial;
            width: initial;
            margin-bottom: 0;
            display: none;
            cursor: pointer;
            &:checked{
                &+label::after{
                    content: '';
                    position: absolute;
                    top: 1px;
                    left: 10px;
                    width: 4px;
                    height: 14px;
                    border: solid #990000;
                    border-width: 0px 5px 5px 0px;
                    transform: rotate(45deg);
                }
            }
        }
        label{
            position: relative;
            cursor: pointer;
            &::before{
                content: '';
                -webkit-appearance: none;
                background-color: transparent;
                padding: 10px;
                display: inline-block;
                position: relative;
                vertical-align: middle;
                cursor: pointer;
                margin-right: 20px;
                width: 28px;
                height: 28px;
                border: 1px solid #990000;
                border-radius: 4px;
                box-sizing: border-box;
                top: -2px;
            }
        }
    }
}