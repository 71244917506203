.sd-email-modal {
    & * {
        font-family: 'Roboto', sans-serif;
    }
    & .modal-header {
        text-align: right;
        & button {
            padding: 0px;
            box-shadow: none;
            background: transparent;
            position: absolute;
            right: 10px;
            top: 10px;
            & svg {
                font-size: 30px;
            }
        }
    }
    & .modal-title {
        font-weight: 700;
        font-size: 20px;
        letter-spacing: -0.387234px;
        margin-bottom: 20px;
        text-align: center;
    }
    & .MuiPaper-root {
        max-width: 600px;
        width: 100%;
        padding: 30px;
        display: block;
        border-radius: 8px;
    }
    & .MuiDialogTitle-root,
    & .MuiDialogContent-root,
    & .MuiDialogActions-root {
        overflow: hidden;
        padding: 0px;
    }
    & .modal-footer {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 25px;
        column-gap: 10px;
        flex-wrap: wrap;
        row-gap: 10px;
        & button.button {
            max-width: 200px;
            width: 100%;
            margin: 0px;
        }
    }
}
.sd-email-modal-content{
    p{
        text-align: left;
        font-size: 18px;
        letter-spacing: -0.387234px;
        margin-bottom: 0px;
        text-align: left;
    }
    & button.button {
        max-width: max-content;
        margin-bottom: 20px;
        text-transform: none;
    }
}
.sd-email-option {
    display: flex;
    align-items: flex-start;
    margin-bottom: 10px;
    & .sd-email-value {
        flex: 1;
        display: flex;
        align-items: center;
        position: relative;
        & label {
            font-size: 18px;
            letter-spacing: -0.387234px;
            color: #990000;
            font-weight: normal;
            margin-top: 3px;
            cursor: pointer;
            &.error {
                color: red;
            }
        }
    }
}
.sd-email-fields-radio{
    input{
        padding: 0;
        height: initial;
        width: initial;
        margin-bottom: 0;
        display: none;
        cursor: pointer;
        &:checked{
            &+label::after{
                content: '';
                position: absolute;
                top: -10px;
                left: 4px;
                width: 20px;
                height: 20px;
                border: none;
                background-color: #990000;
                border-radius: 100%;
            }
        }
    }
    label{
        position: relative;
        cursor: pointer;
        font-size: 0;
        top: 0px;
        &::before{
            content: '';
            -webkit-appearance: none;
            background-color: transparent;
            padding: 10px;
            display: inline-block;
            position: relative;
            vertical-align: middle;
            cursor: pointer;
            margin-right: 15px;
            width: 28px;
            height: 28px;
            border: 1px solid #990000;
            border-radius: 100%;
            box-sizing: border-box;
        }
    }
}
.sd-other-text {
    &>p {
        text-align: right;
        margin-top: 10px;
        margin-bottom: 0px;
    }
    & textarea {
        border: 1px solid;
        border-radius: 8px;
        outline: none;
        padding: 15px;
        width: 100%;
        max-width: 99%;
        margin-left: 0.5%;
        resize: none;
        display: block;
        &:focus {
            &::placeholder {
                color: transparent;
            }
            &::-webkit-input-placeholder {
                color: transparent;
            }
            &:-moz-placeholder {
                color: transparent;
            }
            &::-moz-placeholder {
                color: transparent;
            }
            &:-ms-input-placeholder {
                color: transparent;
            }
        }
    }
}
@media only screen and (max-width: 650px){
    .sd-email-model-content {
        padding: 0;
        p{
            font-size: 16px;
            margin-bottom: 15px;
        }
    }
}