.sd-upload-audio-modal {
    & * {
        font-family: 'Roboto', sans-serif;
    }
    & .modal-header {
        text-align: right;
        & button {
            padding: 0px;
            box-shadow: none;
            background: transparent;
            position: absolute;
            right: 15px;
            top: 15px;
            & svg {
                font-size: 34px;
            }
        }
    }
    & .modal-title {
        font-weight: 700;
        font-size: 20px;
        letter-spacing: -0.387234px;
        margin-bottom: 20px;
        text-align: left;
    }
    & .MuiPaper-root {
        max-width: 1260px;
        width: 100%;
        padding: 50px 87px 20px;
        display: block;
        border-radius: 8px;
    }
    & .MuiDialogTitle-root,
    & .MuiDialogContent-root,
    & .MuiDialogActions-root {
        padding: 0px;
        overflow: hidden;
    }
    & .modal-footer {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 25px;
        column-gap: 10px;
        & button.button {
            max-width: 156px;
            width: 100%;
        }
    }
    & .sd-upload-audio-content {
        background: rgba(214, 168, 123, 0.29);
        border-radius: 8px;
        padding: 75px 15px 24px;
        text-align: center;
        cursor: pointer;
        & svg {
            font-size: 85px;
        }
        &  p {
            margin-top: 13px;
            font-size: 20px;
            text-align: center;
            letter-spacing: -0.387234px;
            margin-bottom: 0px;
        }
    }
    & .sd-upload-files-list {
        margin-top: 15px;
        & ul{
            & li {
                display: flex;
                align-items: center;
                justify-content: space-between;
                column-gap: 10px;
                background: rgba(214, 168, 123, 0.29);
                border-radius: 8px;
                padding: 10px;
                & .file-name-icon {
                    display: flex;
                    align-items: center;
                    column-gap: 5px;
                    width: calc(100% - 50px);
                }
                & p.file-name {
                    font-size: 16px;
                    letter-spacing: -0.387234px;
                    margin-bottom: 0px;
                    width: 100%;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                }
                & svg {
                    font-size: 30px;
                    display: block;
                }
                & .remove-file {
                    cursor: pointer;
                }
                &+li {
                    margin-top: 10px;
                }
            }
        }
    }
}

@media only screen and (max-width: 991px){
    .sd-upload-audio-modal {
        & .modal-title {
            font-size: 16px;
        }
        & .MuiPaper-root {
            padding: 50px 20px 20px;
        }
        & .sd-upload-audio-content {
            padding: 60px 15px 24px;
            & svg {
                font-size: 70px;
            }
        }
    }
}

@media only screen and (max-width: 767px){
    .sd-upload-audio-modal {
        & .sd-upload-audio-content {
            & svg {
                font-size: 60px;
            }
            & p {
                font-size: 16px;
            }
        }
    }
}