.sd-contact-us-content {
    padding-bottom: 100px;
    padding-top: 20px;
    & h2 {
        margin-bottom: 20px;
        display: flex;
        align-items: center;
    }
}
.sd-contact-us-form-section {
    border: 1px solid;
    border-radius: 20px;
    padding: 20px;
    & .element-container {
        position: relative;
        &>svg {
            position: absolute;
            right: 3px;
            top: 13px;
            font-size: 22px;
            width: auto !important;
            fill: red;
        }
    }
    & .two-inputs {
        display: flex;
        align-items: center;
        justify-content: space-between;
        column-gap: 18px;
        & .element-container {
            width: 50%;
        }
    }
    & input,
    & textarea {
        line-height: 45px;
        display: flex;
        align-items: center;
        background: rgba(153, 0, 0, 0.05);
        border: 1px solid;
        border-radius: 20px;
        outline: none;
        padding: 0px 15px;
        width: 100%;
    }
    & input {
        margin-bottom: 20px;
    }
    & textarea {
        padding: 15px;
        width: 100% !important;
        min-height: 125px;
        line-height: normal;
        overflow: hidden;
    }
    & button.button {
        width: 100%;
        margin-top: 20px;
        & .sd_loader {
            & svg {
                color: #fff;
            }
        }
    }
    & .recaptch {
        & .g-recaptcha>div {
            border: 1px solid transparent;
            outline: 1px solid transparent;
            border-radius: 3px;
        }
        &.error {
            & .g-recaptcha>div {
                border-color: red;
                outline-color: red;
            }
        }
    }
}

@media only screen and (max-width: 991px){
    .sd-contact-us-form-section {
        & .element-container {
            &>svg {
                font-size: 18px;
            }
        }
    }
}
@media only screen and (max-width: 650px){
    .sd-contact-us-form-section {
        padding: 15px;
        .two-inputs {
            display: block;
            & .element-container {
                width: 100%;
            }
        }
    }
}