.sd-step-one-content {
    position: relative;
    .sd-step-one-heading {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
    }
    .sd-delete-hug {
        cursor: pointer;
        padding: 5px 10px;
        & svg {
            color: #fff;
            font-size: 24px;
            margin-right: 5px;
        }
    }
}
.sd-step-one-form-content {
    &>p {
        text-align: right;
        margin-top: 10px;
        margin-bottom: 0px;
    }
    & textarea {
        border: 1px solid;
        border-radius: 8px;
        outline: none;
        padding: 15px;
        width: 100%;
        min-height: 120px;
        max-width: 100%;
        resize: none;
        display: block;
        &:focus {
            &::placeholder {
                color: transparent;
            }
            &::-webkit-input-placeholder {
                color: transparent;
            }
            &:-moz-placeholder {
                color: transparent;
            }
            &::-moz-placeholder {
                color: transparent;
            }
            &:-ms-input-placeholder {
                color: transparent;
            }
        }
    }
    & button.button {
        margin-bottom: 15px;
        max-width: 195px;
        width: 100%;
        &.remove {
            max-width: 250px;
        }
    }
    & .sd-step-one-inputs {
        display: flex;
        align-items: flex-start;
        column-gap: 15px;
        margin-bottom: 0;
    }
    & .sd-step-one-form-btns {
        display: flex;
        align-items: center;
        justify-content: space-between;
        column-gap: 10px;
    }
    & .MuiAutocomplete-endAdornment {
        display: none;
    }
}
.sd-step-one-inputs {
    margin-bottom: 18px;
    & .element-container {
        position: relative;
        width: 50%;
        margin-bottom: 15px;
        & >* {
            width: 100%;
        }
        &>svg {
            position: absolute;
            right: 3px;
            top: 13px;
            font-size: 22px;
            width: auto !important;
            fill: red;
        }
        &.relationship-element {
            display: none;
        }
    }
    & input {
        line-height: 16px;
        border: 1px solid;
        border-radius: 8px;
        padding: 13px 15px;
        outline: none;
    }
    & .MuiFormControl-root {
        background-color: #fff;
        border-radius: 8px;
        & .MuiFormLabel-root {
            font-size: 16px;
            line-height: 18px;
            color: #990000;
            top: 16px;
            left: 15px;
            transform: none;
            &.MuiFormLabel-filled {
                display: none;
            }
        }
        & .MuiInputBase-root {
            border: 1px solid #990000;
            border-radius: 8px;
            &.error {
                border-color: red;
                outline: 1px solid;
            }
            & .MuiSelect-select {
                padding: 11px 15px 11px;
                color: #990000;
                font-size: 16px;
            }
        }
        & fieldset {
            border: none;
        }
        & svg {
            font-size: 40px;
        }
        & .MuiAutocomplete-inputRoot {
            padding: 13px 15px;
            & input {
                border: none;
                border-radius: 0px;
                padding: 0px;
                height: auto;
                text-transform: capitalize;
            }
        }
    }
}
.sd-relationship-select-menu {
    & .MuiPaper-root {
        border-radius: 0px 0px 8px 8px;
        & ul.MuiMenu-list {
            box-shadow: 0px 0px 10px #0000001a;
            border-radius: 0px;
            padding: 20px 0px;
            & li {
                font-family: 'Roboto', sans-serif;
                font-style: normal;
                letter-spacing: -0.387234px;
                color: #990000;
                padding: 0px 20px;
                font-size: 18px;
                line-height: 30px;
            }
        }
    }
}
.sd-custom-relationship-value {
    border-top: 4px solid #990000;
    max-width: 92%;
    margin: auto;
    margin-top: 20px;
    label{
        font-family: 'Roboto', sans-serif;
        font-size: 18px;
        color: #990000;
        display: block;
        margin: 20px 0;
    }
    input{
        background: #FBF7F3;
        border: 1px solid;
        border-radius: 8px;
        line-height: 30px;
        width: 100%;
        padding: 10px 20px;
        font-family: 'Roboto', sans-serif;
        outline: none;
    }
}
@media only screen and (max-width: 991px){
    .sd-step-one-form-content {
        & button.button {
            &.remove {
                max-width: 220px;
            }
        }
        & textarea {
            padding: 15px;
        }
    }
    .sd-step-one-inputs {
        & input {
            padding: 15px;
            font-size: 16px;
            line-height: 18px;
            letter-spacing: 2px;
        }
        & .element-container {
            &>svg {
                top: 15px;
                font-size: 18px;
            }
        }
        & .MuiFormControl-root {
            & .MuiFormLabel-root {
                font-size: 16px;
                line-height: 16px;
                top: 18px;
                left: 15px;
                letter-spacing: 2px;
            }
            & .MuiInputBase-root {
                & .MuiSelect-select {
                    padding: 15px;
                    font-size: 16px;
                    line-height: 18px;
                    min-height: auto;
                    letter-spacing: 2px;
                }
            }
        }
    }
    .sd-relationship-select-menu {
        & .MuiPaper-root {
            & ul.MuiMenu-list {
                padding: 10px 0px 20px;
                & li {
                    padding: 0px 15px;
                    min-height: auto;
                }
            }
        }
    }
    .sd-custom-relationship-value {
        border-top-width: 2px;
    }
}
@media only screen and (max-width: 767px){
    .sd-step-one-form-content {
        & .sd-step-one-inputs{
            display: block;
            margin-bottom: 10px;
            & .element-container {
                width: 100%;
                margin-bottom: 5px;
            }
        }
    }
    .sd-step-one-form-content {
        & button.button {
            max-width: 160px;
        }
    }
}
@media only screen and (max-width: 650px){
    .sd-relationship-select-menu {
        & .MuiPaper-root {
            & ul.MuiMenu-list {
                & li {
                    font-size: 16px;
                }
            }
        }
    }
    .sd-custom-relationship-value { 
        label{
            margin: 10px 0;
        }
        input{
            line-height: 24px;
        }
    }
}