.sd-step-two-icons {
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 110px;
    &>p {
        margin-bottom: 0px;
        font-weight: 700;
        font-size: 22px;
        text-align: center;
        letter-spacing: -0.387234px;
    }
    & .sd-icon {
        background: linear-gradient(233.87deg, #FFFFFF 2.19%, rgba(255, 255, 255, 0.19) 99.96%);
        box-shadow: 0px 0px 10px #00000040;
        border-radius: 8px;
        width: 80px;
        height: 80px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: auto auto 8px auto;
        cursor: pointer;
        & svg {
            font-size: 40px;
        }
        &.disabled {
            cursor: default;
        }
    }
    & div p {
        font-weight: 700;
        font-size: 16px;
        line-height: 22px;
        text-align: center;
        letter-spacing: -0.387234px;
        margin-bottom: 10px;
        &.audio-note {
            font-size: 13px;
            line-height: 15px;
            letter-spacing: 1.11277px;
            text-decoration-line: underline;
            margin-bottom: 0px;
            cursor: pointer;
        }
    }
}
.sd-step2-note {
    margin-bottom: 30px;
    & h3{
        margin-bottom: 10px;
    }
    & p {
        letter-spacing: -0.387234px;
        margin-bottom: 0px;
        & .audio-note {
            text-decoration-line: underline;
            cursor: pointer;
        }
    }
}
.sd-audio-videos-list {
    background: rgba(196, 196, 196, 0.1);
    border-radius: 25px;
    padding: 20px;
    margin-top: 30px;
    & ul {
        margin-bottom: 0px;
        max-height: 430px;
        overflow-y: auto;
    }
    & li {
        padding: 10px 20px;
        background: rgba(214, 168, 123, 0.09);
        border: 1px solid;
        border-radius: 8px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        & div {
            display: flex;
            justify-content: space-between;
            align-items: center;
        }
        &+li {
            margin-top: 11px;
        }
        & * {
            color: #990000;
        }
        & p {
            font-size: 18px;
            line-height: 22px;
            text-align: left;
            letter-spacing: -0.387234px;
            margin-bottom: 0px;
        }
        & svg {
            font-size: 35px;
        }
        & .file-icon {
            & svg {
                width: 35px;
                height: 35px;
                font-size: 35px;
            }
        }
        & .icon-name {
            column-gap: 34px;
        }
        & .date-actions {
            column-gap: 190px;
        }
        & .actions {
            column-gap: 40px;
            & svg {
                cursor: pointer;
            }
        }
        & .sd_loader {
            height: 30px;
        }
    }
}
.sd-file-check {
    input{
        padding: 0;
        height: initial;
        width: initial;
        margin-bottom: 0;
        display: none;
        cursor: pointer;
        &.error {
            &+label::before {
                border-color: red;
            }
        } 
        &:checked{
            &+label::after{
                content: '';
                position: absolute;
                top: 3px;
                left: 10px;
                width: 4px;
                height: 14px;
                border: solid #990000;
                border-width: 0px 5px 5px 0px;
                transform: rotate(45deg);
            }
        }
    }
    label{
        position: relative;
        cursor: pointer;
        font-size: 0;
        &::before{
            content: '';
            -webkit-appearance: none;
            background-color: transparent;
            padding: 10px;
            display: inline-block;
            position: relative;
            vertical-align: middle;
            cursor: pointer;
            margin-right: 0px;
            width: 28px;
            height: 28px;
            border: 1px solid #990000;
            border-radius: 4px;
            box-sizing: border-box;
        }
    }
}

@media only screen and (max-width: 991px){
    .sd-step-two-icons {
        column-gap: 80px;
        & div p {
            &.audio-note {
                max-width: 150px;
            }
        }
    }
    .sd-audio-videos-list {
        padding: 15px;
        & li {
            & .date-actions {
                column-gap: 40px;
            }
        }
    }
}

@media only screen and (max-width: 767px){
    .sd-step-two-icons {
        column-gap: 20px;
        &>div {
            flex: 1;
        }
        &>p {
            flex: 0;
        }
        & div p {
            font-size: 16px;
            &.audio-note {
                max-width: 100%;
            }
        }
    }
    .sd-audio-videos-list {
        & li {
            padding: 10px;
            & p {
                font-size: 18px;
            }
            & .icon-name,
            & .date-actions {
                column-gap: 10px;
            }
            & .actions {
                column-gap: 5px;
            }
            & svg {
                font-size: 30px;
            }
            & .file-icon {
                & svg {
                    width: 30px;
                    height: 30px;
                }
            }
        }
    }
}

@media only screen and (max-width: 560px){
    .sd-audio-videos-list {
        & li {
            & p {
                font-size: 16px;
            }
            & .icon-name {
                flex-direction: column;
                row-gap: 10px;
                align-items: flex-start;
            }
            & .date-actions {
                flex-direction: column;
                row-gap: 10px;
                align-items: flex-end;
            }
        }
    }
}