.sd-reminder-modal {
    & * {
        font-family: 'Roboto', sans-serif;
    }
    & .modal-header {
        text-align: right;
        & button {
            padding: 0px;
            box-shadow: none;
            background: transparent;
            position: absolute;
            right: 15px;
            top: 15px;
            & svg {
                font-size: 34px;
            }
        }
    }
    & .modal-title {
        font-weight: normal;
        font-size: 22px;
        letter-spacing: -0.387234px;
        margin-bottom: 20px;
        text-align: left;
    }
    & .MuiPaper-root {
        max-width: 1250px;
        width: 100%;
        padding: 50px 45px 50px;
        display: block;
        border-radius: 8px;
    }
    &.success {
        & .MuiPaper-root {
            max-width: 600px;
            width: 100%;
            padding: 30px 50px 30px 30px;
            display: block;
            border-radius: 8px;
        }
    }
    & .MuiDialogTitle-root,
    & .MuiDialogContent-root,
    & .MuiDialogActions-root {
        overflow: hidden;
        padding: 0px;
    }
    & .modal-footer {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 25px;
        column-gap: 10px;
        & button.button {
            max-width: 156px;
            width: 100%;
        }
    }
}
.sd-reminder-model-content{
    p{
        font-size: 18px;
        letter-spacing: -0.387234px;
        margin-bottom: 25px;
    }
}
.sd-modal-email-note-fields {
    border-top: 1px solid #990000;
    padding: 25px 0;
}
.sd-flex-items {
    display: flex;
    align-items: center;
    +div{
        margin-top: 15px;
    }
}
.sd-remind-fields-check{
    input{
        padding: 0;
        height: initial;
        width: initial;
        margin-bottom: 0;
        display: none;
        cursor: pointer;
        &:checked{
            &+label::after{
                content: '';
                position: absolute;
                top: -10px;
                left: 10px;
                width: 4px;
                height: 14px;
                border: solid #990000;
                border-width: 0px 5px 5px 0px;
                transform: rotate(45deg);
            }
        }
    }
    label{
        position: relative;
        cursor: pointer;
        font-size: 0;
        top: -2px;
        &::before{
            content: '';
            -webkit-appearance: none;
            background-color: transparent;
            padding: 10px;
            display: inline-block;
            position: relative;
            vertical-align: middle;
            cursor: pointer;
            margin-right: 20px;
            width: 28px;
            height: 28px;
            border: 1px solid #990000;
            border-radius: 4px;
            box-sizing: border-box;
        }
    }
}
.sd-remind-value{
    flex: 1;
    display: flex;
    align-items: center;
    position: relative;
    label{
        font-weight: 700;
        font-size: 18px;
        letter-spacing: -0.387234px;
        color: #990000;
        margin-right: 5px;
        min-width: 75px;
    }
    input{
        background: #FFFFFF;
        border: 1px solid;
        border-radius: 8px;
        width: 100%;
        max-width: none;
        line-height: 16px;
        padding: 15px 20px 18px;
        margin-right: 1px;
    }
    input:disabled {
        &::placeholder {
            color: #ADADAD !important;
        }
    }
    input:disabled {
        &::-moz-placeholder {
            color: #ADADAD !important;
        }
    }
    input:disabled {
        &::-webkit-input-placeholder {
            color: #ADADAD !important;
        }
    }
    >svg {
        position: absolute;
        right: 3px;
        top: 15px;
        font-size: 22px;
        width: auto !important;
        fill: red;
    }
}
.sd-reminder-sets-container {
    background: rgba(214, 168, 123, 0.26);
    border-radius: 14px;
    padding: 15px;
    overflow: hidden;
    max-height: 265px;
    overflow-y: auto;
}
.sd-remind-date-occasion {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    &>div{
        flex: 1;
    }
    &+div{
        margin-top: 15px;
    }
    & .delete-icon {
        cursor: pointer;
        & svg {
            font-size: 30px;
        }
    }
}
.sd-phone-checkmark {
    display: flex;
    align-items: flex-start;
    & .sd-remind-value {
        & label {
            font-weight: normal;
            margin-right: 0px;
            &.error {
                color: red;
            }
        }
    }
    & .router-link{
        color: #990000;
        text-decoration: none;
        font-weight: bold;
        font-size: inherit;
    }
}
.sd-fields-group{
    position: relative;
    +div{
        margin-left: 10px;
    }
    fieldset{
        border: none;
    }
    input{
        padding: 14px 20px;
        line-height: 40px;
        letter-spacing: -0.387234px;
        color: #990000;
    }
    & .MuiFormControl-root {
        & .MuiFormLabel-root {
            top: 16px;
            left: 20px;
            transform: none;
            letter-spacing: -0.387234px;
            color: #990000;
            &.MuiFormLabel-filled {
                display: none;
            }
        }
        & .MuiInputBase-root {
            border: 1px solid #990000;
            border-radius: 8px;
            &.error {
                border-color: red;
                outline: 1px solid;
            }
            & .MuiSelect-select {
                padding: 5px 20px;
                line-height: 40px;
                letter-spacing: -0.387234px;
                color: #990000;
            }
        }
        &.error {
            & .MuiInputBase-root {
                border-color: red;
                outline: 1px solid;
            }
        }
    }
    & .MuiInputAdornment-root {
        & .MuiIconButton-root {
            margin-right: 0px;
        }
    }
    &>svg {
        position: absolute;
        right: 3px;
        top: 15px;
        font-size: 22px !important;
        width: auto !important;
        fill: red;
        margin-right: 0px !important;
    }
}
.sd-occasion-pick{
    svg{
        font-size: 50px;
        color: #990000;
        margin-right: 3px;
    }
}
.sd-occasion-datepick * {
    color: #990000 !important;
    .Mui-selected {
        background-color: #990000 !important;
        color: #fff !important;
    }
}
.sd-occasion-select{
    .MuiMenu-list{
        padding: 20px 0;
        &>li:first-child{
            display: none;
        }
    }
    li{
        min-height: auto;
        font-size: 18px;
        line-height: 30px;
        letter-spacing: -0.387234px;
        color: #990000;
        padding: 0 20px;
        &:hover,
        &.Mui-selected{
            background: rgba(153, 0, 0, 0.07);
        }
    }
}
.sd-custom-occasion-value {
    border-top: 4px solid #990000;
    max-width: 92%;
    margin: auto;
    margin-top: 20px;
    label{
        font-family: 'Roboto', sans-serif;
        font-size: 18px;
        color: #990000;
        display: block;
        margin: 20px 0;
    }
    input{
        background: #FBF7F3;
        border: 1px solid;
        border-radius: 8px;
        line-height: 30px;
        width: 100%;
        padding: 10px 20px;
        font-family: 'Roboto', sans-serif;
        outline: none;
    }
}
.sd-add-remind-set {
    display: flex;
    padding-top: 30px;
    column-gap: 10px;
    button{
        width: 100%;
        background: #FFFFFF !important;
        color: #990000 !important;
        justify-content: space-between;
        text-transform: inherit;
        padding-top: 14px !important;
        padding-bottom: 14px !important;
    }
}
.sd-remind-save{
    margin-top: 30px;
    button{
        width: 100%;
    }
    & .sd_loader {
        color: #fff;
        & svg {
            color: #fff !important
        }
    }
}
@media only screen and (max-width: 1200px){
    .sd-remind-save{
        margin-top: 20px;
    }
}
@media only screen and (max-width: 991px){
    .sd-reminder-modal {
        & .MuiPaper-root {
            padding: 50px 20px 50px;
        }
        &.success {
            & .modal-title {
                font-size: 16px;
            }
            & .MuiPaper-root {
                padding: 30px 50px 30px 20px;
            }
        }
    }
    .sd-remind-value {
        label{
            min-width: 50px;
            font-size: 18px;
        }
    }
    .sd-remind-fields-check{
        label{
            &::before{
                width: 25px;
                height: 25px;
                margin-right: 15px;
            }
        }
        input{
            &:checked{
                &+label::after{
                    top: -9px;
                    left: 9px;
                    width: 3px;
                    height: 12px;
                }
            }
        }
    }
    .sd-reminder-sets-container{
        max-height: 190px;
    }
    .sd-add-remind-set{
        button{
            padding-top: 11px !important;
            padding-bottom: 11px !important;
        }
        padding-top: 30px;
    }
    .sd-custom-occasion-value{ 
        border-top-width: 2px;
    }
    .sd-occasion-pick svg {
        font-size: 35px;
    }
}
@media only screen and (max-width: 650px){
    .sd-reminder-model-content {
        padding: 0;
        p{
            font-size: 16px;
            margin-bottom: 15px;
        }
    }
    .sd-remind-value {
        label{
            min-width: 40px;
            font-size: 15px;
        }
    }
    .sd-remind-fields-check{
            label{
                &::before{
                    width: 20px;
                    height: 20px;
                    margin-right: 5px;
                }
            }
            input{
                &:checked{
                    &+label::after{
                    top: -8px;
                    left: 7px;
                    width: 3px;
                    height: 9px;
                }
            }
        }
    }
    .sd-reminder-sets-container{
        padding: 10px;
        max-height: 160px;
    }
    .sd-remind-date-occasion{
        &>div{
            flex: auto;
            width: 100%;
            +div{
                margin-top: 15px;
                margin-left: 0;
            }
        }
        &+div{
            margin-top: 20px;
        }
    }
    .sd-add-remind-set{
        padding-top: 20px;
    }
    .sd-occasion-select li{
        font-size: 16px;
        padding: 0 20px;
    }
    .sd-custom-occasion-value{ 
        label{
            margin: 10px 0;
        }
        input{
            line-height: 24px;
        }
    }
}