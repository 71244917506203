.sd-terms-privacy-container {
    * {
        font-family: 'Roboto', sans-serif;
        color: #000;
    }
    padding: 40px 0px 100px;
    .two-one-color {
        color: #212121 !important;
    }
    h2{
        margin-bottom: 10px;
        text-align: center;
        font-weight: normal;
    }
    h3{
        text-align: center;
        margin-bottom: 10px;
    }
    h4{
        margin: 15px 0 0;
    }
    p{
        margin-bottom: 0px;
        margin-top: 15px;
        font-size: 15px;
        line-height: 20px;
    }
    a {
        text-decoration: underline;
        color: blue;
        font-size: 15px;
        line-height: 20px;
        &.not-blue {
            color: #000;
        }
        &:hover{
            color: blue;
        }
    }
    ul{
        margin-top: 30px;
        padding-left: 40px;
        list-style-type: none;
        li{
            margin-bottom: 15px;
            font-size: 15px;
            line-height: 20px;
            &::before{
                content: "";
                height: 12px;
                width: 12px;
                display: inline-block;
                background: #000;
                border-radius: 50px;
                margin-right: 20px;
                vertical-align: middle;
            }
        }&+li{
            margin-top: 20px;
        }
    }
    .underline{
        text-decoration: underline;
        font-size: 15px;
        line-height: 20px;
    }
}
@media only screen and (max-width: 991px) {
    .sd-terms-privacy-container{
        ul{
            li{
                &::before{
                    margin-right: 10px;
                    height: 8px;
                    width: 8px;
                }
            }
        }
    }
}
@media only screen and (max-width: 767px) {
    .sd-terms-privacy-container{
        padding: 20px 0px 50px;
        h3{
            margin-bottom: 0px;
        }
        ul{
            padding-left: 20px;
        }
    }
}