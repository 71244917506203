.sd-login-page-content {
    & * {
        font-family: "Roboto", 'sans-serif';
    }
    display: flex;
    justify-content: space-between;
    &>div {
        width: 50%;
    }
    .sd-form-section {
        display: flex;
        justify-content: flex-end;
        padding: 30px 20px;
    }
    .password-form-error {
        padding-bottom: 25px;
    }
    .sd-login-form-section {
        width: 100%;
        max-width: 630px;
        .element-container {
            position: relative;
            &>svg {
                position: absolute;
                right: 3px;
                top: 15px;
                font-size: 22px;
                width: auto !important;
                fill: red;
            }
        }
        & .two-inputs {
            display: flex;
            align-items: center;
            justify-content: space-between;
            column-gap: 20px;
            & .element-container {
                width: 50%;
            }
        }
        & input {
            border: 1px solid;
            border-radius: 6px;
            line-height: 16px;
            width: 100%;
            outline: none;
            padding: 13px 15px;
            margin-bottom: 25px;
            &.error {
                border-color: red;
            }
        }
        & .form-inputs {
            &>input:last-child {
                margin-bottom: 0px;
            }
        }
        & .sd-phone-checkmark {
            margin-bottom: 25px;
            margin-top: -15px;
        }
    }
    & .sd-img-section {
        height: 100vh;
        position: fixed;
        right: 0px;
        & img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            display: block;
            object-position: center;
        }
        &.sd-login-img {
            & img {
                object-position: bottom;
            }
        }
    }
    & h1 {
        font-size: 26px;
        line-height: normal;
        letter-spacing: -0.995745px;
        padding-top: 30px;
        padding-bottom: 20px;
        margin-bottom: 0px;
    }
    & .buttons-group {
        display: flex;
        justify-content: space-between;
        align-items: center;
        column-gap: 20px;
        & >* {
            width: 50%;
            & button {
                width: 100%;
            }
        }
        & a {
            text-decoration: none;
            & button.button {
                width: 100%;
            }
        }
        & button.button {
            &.facebook {
                background-color: #1A6ED9;
                border: transparent;
                text-transform: none;
                padding-top: 13px;
                padding-bottom: 13px;
            }
            &.google {
                background-color: #EA4335;
                border: transparent;
                text-transform: none;
                padding-top: 13px;
                padding-bottom: 13px;
            }
            & svg {
                margin-right: 20px;
                color: #fff;
            }
        }
    }
    & .or-divider {
        position: relative;
        margin-top: 25px;
        margin-bottom: 25px;
        & p {
            font-size: 20px;
            line-height: 23px;
            letter-spacing: -0.553191px;
            color: #000;
            max-width: 95px;
            background-color: #fff;
            text-align: center;
            margin: 0px auto;
            &::before {
                content: "";
                border-bottom: 1px solid #000;
                width: 100%;
                height: 1px;
                position: absolute;
                left: 0px;
                right: 0px;
                top: 10px;
                z-index: -1;
            }
        }
    }
    & .switch-forgot {
        padding-bottom: 25px;
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        & p.link {
            margin: 0px;
            color: #990000;
            cursor: pointer;
            display: none;
        }
        &.switch-terms {
            & .MuiSwitch-root {
                display: none;
            }
        }
    }
    & .switch-button {
        & .MuiFormControlLabel-root {
            margin-left: 0px;
            margin-right: 0px;
            &.error {
                color: red;
            }
        }
        & .MuiSwitch-root {
            width: 42px;
            height: 20px;
            padding: 0px;
            display: flex;
            margin-right: 7px;
            &:active {
                & .MuiSwitch-thumb {
                    width: 15px;
                }
                & .MuiSwitch-switchBase.Mui-checked {
                    transform: translateX(9px)
                }
            }
            & .MuiSwitch-switchBase {
                padding: 2px;
                &.Mui-checked {
                transform: translateX(22px);
                color: #fff;
                & + .MuiSwitch-track {
                    opacity: 1;
                    background-color: #990000;
                }
                }
            }
            & .MuiSwitch-thumb {
                box-shadow: 0px 4px 6px -1px rgba(0, 0, 0, 0.12), 0px 2px 4px -1px rgba(0, 0, 0, 0.07);
                width: 16px;
                height: 16px;
                border-radius: 8px;
                transition: width 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
            }
            & .MuiSwitch-track {
                border-radius: 10.5px;
                opacity: 1;
                background-color: rgba(0,0,0,.25);
                box-sizing: border-box;
            }
        }
        & .MuiFormControlLabel-label {
            & div{
                font-size: 14px;
                line-height: 16px;
                letter-spacing: -0.387234px;
                & a {
                    color: #990000;
                    text-decoration: none;
                    font-weight: bold;
                    font-size: inherit;
                }
            }
        }
    }
    & .sd-error-message {
        padding: 10px 0px;
    }
    & .sd-verify-account {
        & button.button {
            width: 100%;
        }
        & .resend-code {
            margin-top: 20px;
            margin-bottom: 0px;
            text-align: center;
            & .anchor {
                cursor: pointer;
            } 
        }
    }
}

@media only screen and (max-width: 991px){
    .sd-login-page-content {
        & .sd-login-form-section {
            max-width: 100%;
            & .element-container {
                &>svg {
                    top: 15px;
                    font-size: 18px;
                }
            }
        }
        & .buttons-group {
            column-gap: 10px;
        }
        .sd-form-section {
            padding-top: 20px;
            padding-bottom: 20px;
            width: 100%;
        }
        .sd-img-section {
            display: none;
        }
    }
}
@media only screen and (max-width: 480px){
    .sd-login-page-content {
        & .sd-login-form-section {
            & .two-inputs {
                display: block;
                .element-container {
                    width: 100%;
                }
            }
        }
        & .buttons-group {
            &.social-buttons {
                & >* {
                    width: 100%;
                }
                display: block;
                & button {
                    width: 100%;
                    &.google {
                        margin-top: 10px;
                    }
                }
            }
        }
    }
}