.sd-create-hug-content {
    & * {
        font-family: 'Roboto', sans-serif;
    }
}
.sd-create-hug-steps {
    background-color: #990000;
    padding: 8px 0px;
    & .MuiStep-root {
        cursor: pointer;
        &:last-child {
            flex: 0;
        }
        &.sd-step-completed {
            & span.MuiStepLabel-label {
                color: #fff;
            }
            & .MuiStepLabel-root {
                cursor: pointer;
                & .MuiStepLabel-iconContainer {
                    border-color: #fff;
                    & svg {
                        color: #fff;
                        background-color: #fff;
                        border-color: #990000;
                    }
                }
            }
            & .MuiStepConnector-root {
                & .MuiStepConnector-line {
                    border-color: #fff;
                }
            }
        }
        & .MuiStepLabel-labelContainer {
            text-align: left;
        }
        & span.MuiStepLabel-label {
            margin-bottom: 5px;
            margin-top: 0px;
            font-size: 16px;
            line-height: 20px;
            min-height: 20px;
            letter-spacing: -0.387234px;
            color: rgba(255, 255, 255, 0.35);
            &.Mui-active,
            &.Mui-completed {
                color: #fff;
            }
        }
        & .MuiStepLabel-root {
            flex-direction: column-reverse;
            align-items: flex-start;
            & .MuiStepLabel-iconContainer {
                border: 3px solid rgba(255, 255, 255, 0.35);
                border-radius: 100%;
                &.Mui-active,
                &.Mui-completed {
                    border-color: #fff;
                }
                & svg {
                    color: rgba(255, 255, 255, 0.35);
                    border: 3px solid transparent;
                    border-radius: 100%;
                    &.Mui-active,
                    &.Mui-completed {
                        color: #fff;
                        background-color: #fff;
                        border-color: #990000;
                    }
                    & text {
                        display: none;
                    }
                }
            }
        }
        & .MuiStepConnector-root {
            left: -235px;
            right: 0px;
            top: 38px;
            width: 220px;
            cursor: auto;
            & .MuiStepConnector-line {
                border: 3px solid rgba(255, 255, 255, 0.35);
            }
            &.Mui-active,
            &.Mui-completed {
                & .MuiStepConnector-line {
                    border-color: #fff;
                }
            }
        }
    }
}
.sd-create-hug-steps-section {
    background-color: #FBF6F2;
    padding: 30px 0px 80px;
    &.min-height {
        min-height: calc(100vh - 208px);
    }
}
.sd-create-hug-footer-btns {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 20px;
    column-gap: 10px;
    & .buttons-group {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        column-gap: 30px;
        flex: auto;
    }
    & button.button {
        max-width: 180px;
        width: 100%;
        & svg {
            color: #fff;
        }
    }
    &.center {
        padding-top: 48px;
        justify-content: center;
        & button.button {
            max-width: 300px;
        }
    }
}
@media only screen and (max-width: 1200px){
    .sd-create-hug-steps {
        & .MuiStep-root {
            & .MuiStepConnector-root {
                left: -200px;
                width: 180px;
            }
        }
    }
}
@media only screen and (max-width: 1080px){
    .sd-create-hug-steps {
        & .MuiStep-root {
            & .MuiStepConnector-root {
                left: -180px;
                width: 160px;
            }
        }
    }
}
@media only screen and (max-width: 991px){
    .sd-create-hug-steps {
        & .MuiStep-root {
            & .MuiStepConnector-root {
                left: -150px;
                width: 140px;
                top: 44px;
            }
        }
    }
    .sd-create-hug-footer-btns {
        & .buttons-group {
            column-gap: 10px;
        }
        & button.button {
            font-size: 20px;
            line-height: 24px;
            padding: 12px 10px;
        }
    }
}
@media only screen and (max-width: 899px){
    .sd-create-hug-steps-section {
        &.min-height {
            min-height: auto;
        }
    }
    .sd-create-hug-steps {
        & .MuiStepper-root {
            display: block;
        }
        & .MuiStep-root {
            padding-left: 0px;
            padding-right: 0px;
            padding-bottom: 40px;
            &:last-child {
                padding-bottom: 0px;
            }
            & span.MuiStepLabel-label {
                margin-bottom: 0px;
            }
            & .MuiStepLabel-root {
                flex-direction: row;
                column-gap: 10px;
                align-items: center;
            }
            & .MuiStepConnector-root {
                left: 10px;
                width: 10px;
                height: 30px;
                top: -35px;
                & .MuiStepConnector-line {
                    height: 100%;
                }
            }
        }
    }
}
@media only screen and (max-width: 767px){
    .sd-create-hug-footer-btns {
        & button.button {
            font-size: 18px;
            line-height: 21px;
        }
        &.center {
            & button.button {
                max-width: 250px;
                width: 100%;
            }
        }
    }
}
@media only screen and (max-width: 680px){
    .sd-create-hug-footer-btns {
        align-items: flex-start;
        & .buttons-group {
            flex-direction: column-reverse;
            align-items: flex-end;
            row-gap: 10px;
        }
    }
}
@media only screen and (max-width: 480px){
    .sd-create-hug-footer-btns {
        &>button.button {
            width: 50%;
        }
        & .buttons-group {
            align-items: center;
            width: 50%;
        }
    }
}