.sd-footer-create-section {
    background: #990000;
    padding: 27px 20px 30px;
    text-align: center;
    & h3 {
        color: #fff;
        font-size: 30px;
    }
    & button.button {
        margin: 17px auto 0px;
        color: #990000;
        background-color: #fff;
        box-shadow: 0px 20px 30px rgba(3, 15, 26, 0.2);
        text-transform: capitalize;
        font-size: 18px;
        &:hover {
            background-color: #fff;
        }
    }
}
.sd-footer-links-section {
    padding: 45px 0px 80px;
    & .copyright {
        line-height: 20px;
        margin-bottom: 0px;
    }
    & .sd-footer-logo-section {
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-bottom: 3px solid;
        padding-bottom: 13px;
        margin-bottom: 15px;
        & .list {
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;
            -webkit-box-orient: horizontal;
            -webkit-box-direction: normal;
            -ms-flex-direction: row;
            flex-direction: row;
            margin-top: 0px;
            display: flex;
            padding-left: 0;
            margin-bottom: 0;
            list-style: none;
            padding: 0;
            & .listItem {
                color: inherit;
                position: relative;
                display: block;
                width: auto;
                margin: 0;
                padding: 0;
                margin-right: 30px;
                &:last-child {
                    margin-right: 0px;
                }
                & .navLink {
                    font-size: 14px;
                    text-transform: uppercase;
                    position: relative;
                    font-weight: 400;
                    line-height: 24px;
                    text-decoration: none;
                    margin: 0px;
                    display: inline-flex;
                    align-items: center;
                    color: #990000;
                    padding: 0px;
                }
            }
        }
    }
}
.sd-user-footer {
    background: #990000;
    padding: 20px 0;
    .container {
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-wrap: wrap;
    }
    .copyright {
        font-family: 'Exo', sans-serif;
        font-style: normal;
        font-weight: 700;
        font-size: 14px;
        line-height: 19px;
        letter-spacing: -0.387234px;
        text-transform: capitalize;
        color: #FFFFFF;
        margin-bottom: 0;
    }
    .menu-list {
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        display: flex;
        padding: 0;
        .listItem {
            width: auto;
            padding: 0;
            margin-right: 30px;
            &:last-child {
                margin-right: 0px;
            }
        }
    }
    .navLink {
        color: #fff;
        text-decoration: none;
        font-style: normal;
        font-size: 14px;
        line-height: 24px;
        letter-spacing: 0.05em;
        text-transform: uppercase;
    }
}
@media only screen and (max-width: 899px) {
    .sd-footer-links-section {
        & .sd-footer-logo-section {
            & .list {
                display: flex;
                & .listItem {
                    width: auto;
                }
            }
        }
    }
    .sd-user-footer{
        & .container {
            flex-direction: column;
            row-gap: 20px;
            justify-content: center;
        }
    }
}

@media only screen and (max-width: 600px) {
    .sd-footer-links-section {
        & p {
            text-align: center;
        }
        & .sd-footer-logo-section {
            flex-direction: column;
            row-gap: 20px;
        }
    }
    .sd-footer-create-section {
        & h3 {
            font-size: 25px;
        }
        & button.button {
            padding: 15px 20px;
        }
    }
    .sd-user-footer{
        & .menu-list .listItem{
            margin-right: 20px;
        }
        & .navLink{
            font-size: 12px;
        }
    }
}
@media only screen and (max-width: 480px) {
    .sd-user-footer {
        padding: 15px 0;
        .menu-list {
            flex-direction: column;
            .listItem{
                margin-right: 0px;
            }
        }
    }
}