@font-face {
    font-family: 'brandon';
    font-weight: 400;
    src: local('brandon_regular'), url(../fonts/brandon_regular.otf) format('opentype');
}
@font-face {
    font-family: 'brandon';
    font-weight: 500;
    src: local('brandon_medium'), url(../fonts/brandon_medium.otf) format('opentype');
}
@font-face {
    font-family: 'brandon';
    font-weight: 700;
    src: local('brandon_bold'), url(../fonts/brandon_bold.otf) format('opentype');
}
@font-face {
    font-family: 'brandon';
    font-weight: 900;
    src: local('brandon_black'), url(../fonts/brandon_black.otf) format('opentype');
}

$brandColor: #990000;
$textColor: #990000;
$buttonBgColor: #990000;

*, html, body {
    padding: 0px;
    margin: 0px;
    box-sizing: border-box;
    font-family: 'brandon','sans-serif';
    font-size: 16px;
}
html, body, body #root, #root > div.App {
    min-height: 100vh;
}
body {
    background-color: #fff;
    color: $textColor;
}
p {
    color: $textColor;
    font-size: 16px;
}
h1, h2, h3, h4, h5, h6 {
    margin: 0 0 .8em;
    -ms-word-wrap: break-word;
    word-wrap: break-word;
    color: $textColor;
}
h1 {
    font-size: 50px;
}
h2 {
    font-size: 36px;
    margin-bottom: 38px;
}
h3 {
    font-size: 25px;
}
h4{
    font-size: 20px;
}
h2, h3 {
    font-weight: 700;
}
h3, h4 {
    margin-bottom: 30px;
}
h5 {
    font-size: 20px;
}
h5, h6 {
    font-weight: 700;
    margin-bottom: 25px;
}
.container {
    max-width: 1230px;
    margin-right: auto;
    margin-left: auto;
    width: 100%;
    padding-right: 15px !important;
    padding-left: 15px !important;
}
blockquote, code, dl, fieldset, ol, p, pre, table, ul {
    margin-bottom: 20px;
}
.layout_page_home.user_unauthorized header{
    border-bottom: none;
}
#global_content,
.sd-section-bg {
    min-height: calc(100vh - 137px);
}
.sd-section-bg {
    background-color: rgba(153, 0, 0, 0.05);
    padding-bottom: 25px;
}
.sd_loader {
    width: 70px;
    height: 70px;
    margin: 0px auto;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    &.align_left {
        margin-left: 0px;
        text-align: left;
        justify-content: flex-start;
    }
    &.align_right {
        margin-right: 0px;
        text-align: right;
        justify-content: flex-end;
    }
}
#root > .sd_loader {    
    margin: auto;
    position: absolute;
    top: 0px;
    bottom: 0px;
    left: 0px;
    right: 0px;    
}
.app_debug_info {
    margin-top: 15px;
    li {
        margin-bottom: 5px;
        word-break: break-all;
    }
}
.layout_page_profile.user_authorized,
.App.app_transparent_header {
    header.mui-fixed + .main-container{
        padding-top: 0px;
    }
    #global_content {
        padding-top: 0px;
    }
}
.text-center{
    text-align: center;
}
html.overflow_hidden, html.overflow_hidden body {
    overflow: hidden !important;
    height: 100%;
    min-height: 0px;
    position: relative;
}
html.overflow_hidden {
    div.App, #global_wrapper, #global_content, #global_content div {
        overflow: hidden;
    }
}
.video-player {
    padding: 12px;
    border: 1px solid #eee;
    width: 100%;
    .video-container {
        position: relative;
        overflow: hidden;
        padding-bottom: 56.25%;
        height: 0px;
        iframe {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }
    }
}
.separator {
    border-bottom: 1px solid #eee;
    margin-bottom: 35px;
    width: 100%;
}
a.buttonlink {
    text-decoration: none;
}
button.button {
    background-color: $buttonBgColor;
    color: #fff;
    border: 1px solid;
    border-color: $buttonBgColor;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    display: flex;
    align-items: center;
    letter-spacing: -0.387234px;
    padding: 15px;

    & a {
        text-decoration: none;
        color: #fff;
    }
    &.transparent {
        background-color: #fff;
        color: $buttonBgColor;
        &.Mui-disabled {
            color: #990000b3;
        }
        & a {
            color: $buttonBgColor;
        }
    }
    &:hover {
        background-color: $buttonBgColor;
    }
    &.Mui-disabled {
        color: #ffffffb3;
        background-color: #990000b3;
        border-color: #990000b3;
    }
    & .sd_loader {
        width: auto;
        height: auto;
        & svg {
            margin: 0px !important;
        }
    }
    &.radius-8{
        border-radius: 8px;
    }
    &.radius-10{
        border-radius: 10px;
    }
    &.radius-30{
        border-radius: 30px;
    }
}
input,
textarea {
    color: $textColor;
    &.error {
        border-color: red;
        outline: 1px solid red !important;
    }
}
svg {
    color: $brandColor;
}
input[type="text"],
input[type="email"],
input[type="password"],
textarea {
    outline: none;
}
input[type="text"],
input[type="email"],
input[type="password"],
textarea {
    &::placeholder {
        color: $textColor;
    }
}
input[type="text"],
input[type="email"],
input[type="password"],
textarea {
    &::-moz-placeholder {
        color: $textColor;
    }
}
input[type="text"],
input[type="email"],
input[type="password"],
textarea {
    &::-webkit-input-placeholder{
        color: $textColor;
    }
}
.passwordCheck-valid-customizable {
	color: #19BF00;	
	font-size: 14px;
}
.passwordCheck-notValid-customizable {
	color: #DF3312;	
	font-size: 14px;
}
.passwordCheck-valid-customizable .validation-error-symbol,
.passwordCheck-notValid-customizable .validation-error-symbol {
	margin-right: 5px;
}

.mb-0 {
    margin-bottom: 0px;
}

::-webkit-scrollbar {
    width: 9px;
}
::-webkit-scrollbar-thumb {
    background: $brandColor;
    border-radius: 8px;
}
::-webkit-scrollbar-track {
    background-color: transparent;
}

.st-btns {
    & .st-btn {
        display: none !important;
        &[data-network='facebook'],
        &[data-network='twitter'],
        &[data-network='messenger'],
        &[data-network='gmail'],
        &[data-network='whatsapp'],
        &[data-network='linkedin'] {
            display: inline-block !important;
        }
    }
}
.st-logo,
.st-disclaimer {
    display: none;
}
.app_progressbar {
    padding-bottom: 64px;
}

@media only screen and (max-width: 991px){
    ::-webkit-scrollbar {
        width: 5px;
    }
    button.button{
        padding: 15px;
    }
    h1 {
        font-size: 40px;
    }
    h2 {
        font-size: 30px;
    }

}
@media only screen and (max-width: 899px){
    #global_content,
    .sd-section-bg {
        min-height: calc(100vh - 176px);
    }
}
@media only screen and (max-width: 767px){
    h1 {
        font-size: 30px;
    }
    h2 {
        font-size: 25px;
    }
    h3{
        font-size: 20px;
    }
    h4{
        font-size: 18px;
    }
}
@media only screen and (max-width: 600px){
    #global_content,
    .sd-section-bg {
        min-height: calc(100vh - 165px);
    }
}
@media only screen and (max-width: 480px){
    #global_content,
    .sd-section-bg {
        min-height: calc(100vh - 225px);
    }
}