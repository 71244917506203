.sd-about-us-container {
    * {
        font-family: 'Roboto', sans-serif;
        color: #000;
    }
    padding: 40px 0px 100px;
    & .our-mission {
        text-align: left;
    }
    & h1 {
        font-weight: 300;
        margin-bottom: 15px;
        &:after {
            content: "";
            width: 80px;
            height: 3px;
            display: block;
            background-color: #D6A87B;
            margin-top: 20px;
        }
    }
    h2 {
        margin-bottom: 0px;
    }
    & p {
        line-height: 20px;
    }
}
.sd-about-us-content {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    column-gap: 50px;
    padding: 40px 0px 0px;
    & .sd-about-us-content-section {
        flex: 1;
        & p {
            line-height: 30px;
        }
    }
    .sd-about-us-image-section {
        max-width: 400px;
        width: 100%;
        & img {
            cursor: pointer;
        }
        & p {
            margin-top: 10px;
            text-align: center;
            cursor: pointer;
            text-transform: uppercase;
        }
    }
    & h3 {
        margin-bottom: 20px;
        display: flex;
        align-items: center;
    }
    & img {
        width: 100%;
        display: block;
        border-radius: 30px;
    }
}

.sd-about-us-image-play {
    width: 100%;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    & .play-icon {
        position: absolute;
        cursor: pointer;
    }
    & .play-icon-circle {
        background-color: #990000;
        color: #fff;
        border-radius: 100%;
        width: 80px;
        height: 80px;
        position: relative;
        margin: 0px auto;
        margin-top: 100px;
        & svg {
            position: absolute;
            color: #fff;
            fill: #fff;
            top: 0px;
            bottom: 0px;
            left: 0px;
            right: 0px;
            margin: auto;
            width: 60px;
            height: 60px;
        }
    }
}
.sd-join-community {
    text-align: center;
    margin-top: 40px;
    p {
        text-transform: uppercase;
        font-weight: bold;
        font-size: 24px;
        max-width: 640px;
        margin: 0px auto 30px;
        line-height: normal;
    }
    button.button {
        margin: 0px auto;
        text-transform: capitalize;
    }
}
@media only screen and (max-width: 991px){
    .sd-about-us-container {
        & h2 {
            margin-bottom: 25px;
        }
    }
    .sd-about-us-content {
        display: block;
        & h1 {
            font-size: 35px;
            margin-bottom: 15px;
        }
        & img {
            display: block;
            margin: 30px auto 0px;
        }
        & .sd-about-us-image-section {
            max-width: 400px;
            width: 100%;
            margin: 0px auto;
        }
        & .sd-about-us-content-section {
            & p {
                line-height: 20px;
            }
        }
    }
    .sd-about-us-image-play {
        & .play-icon-circle {
            width: 60px;
            height: 60px;
            margin-top: 110px;
            & svg {
                width: 50px;
                height: 50px;
            }
        }
    }
    .sd-join-community {
        p {
            font-size: 20px;
        }
    }
}