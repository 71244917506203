.signup-confirm-modal {
    & * {
        font-family: 'Roboto', sans-serif;
    }
    & .modal-header {
        text-align: right;
        & button {
            padding: 0px;
            box-shadow: none;
            background: transparent;
            position: absolute;
            right: 15px;
            top: 15px;
            & svg {
                font-size: 34px;
            }
        }
    }
    & .MuiPaper-root {
        max-width: 600px;
        width: 100%;
        padding: 50px 45px 50px;
        display: block;
        border-radius: 8px;
    }
    & .MuiDialogTitle-root,
    & .MuiDialogContent-root,
    & .MuiDialogActions-root {
        overflow: hidden;
        padding: 0px;
    }
    .signup-confirm-buttons {
        display: flex;
        justify-content: center;
    }
    .sd-login-page-content > div {
        width: 100%;
        padding: 0px;
    }
    .sd-login-page-content h1 {
        padding-top: 0px;
    }
}