.sd-hug-video-modal {
    & * {
        font-family: 'Roboto', sans-serif;
    }
    & .modal-header {
        text-align: right;
        padding: 0px;
        & button {
            padding: 0px;
            box-shadow: none;
            background: #000;
            position: absolute;
            right: 10px;
            top: 10px;
            z-index: 1;
            & svg {
                font-size: 30px;
                color: #fff;
            }
        }
    }
    & .MuiPaper-root {
        max-width: 1200px;
        width: 100%;
        padding: 0px;
        display: block;
        border-radius: 8px;
    }
    & .MuiDialogTitle-root,
    & .MuiDialogContent-root,
    & .MuiDialogActions-root {
        overflow: hidden;
        padding: 0px;
    }
}
.sd-hug-video-modal-content {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    & video {
        width: 100%;
        display: block;
        max-height: calc(100vh - 64px);
        cursor: pointer;
    }
    & .play-icon {
        position: absolute;
        cursor: pointer;
    }
    & .play-icon-circle {
        height: 120px;
        width: 120px;
        background-color: #990000;
        color: #fff;
        border-radius: 100%;
        position: relative;
        margin: 0px auto;
        & svg {
            position: absolute;
            top: 0px;
            bottom: 0px;
            left: 0px;
            right: 0px;
            margin: auto;
            font-size: 100px;
            color: #fff;
        }
    }
}
@media only screen and (max-width: 991px){
    .sd-hug-video-modal-content {
        & .play-icon {
            display: none;
        }
    }
}
@media only screen and (max-width: 600px) {
    .sd-hug-video-modal-content {
        & .play-icon-circle {
            width: 60px;
            height: 60px;
            & svg {
                font-size: 50px;
            }
        }
    }
}