.sd-user-homepage{
    padding-top: 20px;
}
.sd-home-welcome-note{
    background: #FFFFFF;
    border-radius: 8px;
    padding: 5px 15px;
    h4{
        font-style: normal;
        font-weight: 700;
        font-size: 20px;
        line-height: 31px;
        letter-spacing: -0.387234px;
        text-transform: capitalize;
        font-family: 'Exo', sans-serif;
        text-decoration: none;
        margin-bottom: 0;
        // span{
        //     color: #990000;
        //     @extend h4;
        // }
    }
    span, a{
        font-family: 'Exo', sans-serif;
        font-style: normal;
        font-weight: normal;
        text-align: justify;
        letter-spacing: -0.387234px;
        margin-bottom: 0px;
        color: #990000;
        text-transform: none;
        & a {
            font-weight: bold;
            text-decoration: underline;
            color: blue;
        }
    }
}
.sd-hugs-content-container {
    background-color: #fff;
    border: 1px solid;
    border-radius: 0px 0px 8px 8px;
}
.sd-hugs-items-container{
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    padding: 10px;
    column-gap: .5%;
    &.list-items {
        padding: 5px;
        display: block;
        & .sd-hug-list-item {
            display: flex;
            width: 100%;
            column-gap: 20px;
            & .sd-hug-item {
                max-width: 350px;
                width: 100%;
                flex-shrink: 0;
                margin-bottom: 0px;
                & .sd-hug-options{
                    display: none;
                }
                & img {
                    height: 200px;
                }
            }
        }
        & .sd-hug-item-detail {
            & .sd-hug-options {
                position: static;
            }
            & .bold {
                font-weight: bold;
            }
            & .time{
                &:before {
                    content: "";
                    width: 3px;
                    height: 3px;
                    border-radius: 100%;
                    display: inline-block;
                    background-color: #990000;
                    margin: 0px 5px;
                    vertical-align: middle;
                }
            }
        }
    }
    & .sd-hug-item {
        position: relative;
        width: 24.6%;
        box-sizing: border-box;
        margin-bottom: 5px;
        &>a.nocursor {
            cursor: default;
            text-decoration: none;
        }
        & .video-duration {
            position: absolute;
            bottom: 5px;
            right: 5px;
            margin: 0px;
            color: #fff;
            background-color: #99050080;
            border-radius: 3px;
            padding: 0px 3px;
            font-size: 12px;
        }
    }
    & .play-icon-circle{
        height: 96px;
        width: 96px;
    }
}
.sd-hug-content {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
        max-width: 600px;
        width: 100%;
        height: 170px;
        object-fit: cover;
        border-radius: 12px;
        &.border {
            border: 2px solid #990000;
        }
    }
    .play-icon {
        position: absolute;
    }
    .play-icon-circle {
        height: 60px;
        width: 60px;
        background-color: #990000;
        color: #fff;
        border-radius: 100%;
        position: relative;
        margin: 0px auto;
        svg {
            position: absolute;
            top: 0px;
            bottom: 0px;
            left: 0px;
            right: 0px;
            margin: auto;
            font-size: 40px;
            color: #fff;
        }
    }
    .sd-hug-pending-content {
        height: 170px;
        border-radius: 12px;
        border: 2px solid #990000;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        p {
            font-size: 24px;
            margin: 0px;
            font-weight: bold;
            text-transform: uppercase;
        }
        .sd_loader {
            span {
                width: 50px !important;
                height: 50px !important;
            }
        }
        &.list-view {
            height: 80px;
            .sd_loader {
                width: 30px;
                height: 30px;
                span {
                    width: 30px !important;
                    height: 30px !important;
                }
                svg {
                    background-color: transparent;
                    color: #990000;
                }
            }
            p {
                font-size: 14px;
            }
        }
    }
}
.sd-hug-options {
    display: flex;
    align-items: center;
    position: absolute;
    top: 5px;
    right: 10px;
    color: #fff;
    z-index: 1;
    .sd-email-hug {
        button.button {
            padding: 5px;    
            text-transform: capitalize;
            font-weight: bold;
            border-color: #fff;
        }
    }
    svg{
        color: #fff;
        display: block;
        font-size: 28px;
        background-color: #990000;
        border-radius: 5px;
        padding: 3px;
    }
    span {
        padding: 0 1px;
        cursor: pointer;
    }
    a {
        span {
            display: block;
        }
    }
    .sd-schedule-hug {
        svg {
            font-size: 26px;
        }
    }
    .sd-home-hug-socialshare {
        .st-btn {
            display: none !important;
            &.st-last {
                display: inline-block !important;
                top: 0px !important;
                background-color: #990000 !important;
                padding: 3px !important;
                & img {
                    width: 100% !important;
                    height: 100% !important;
                    top: 0px !important;
                }
            }
        }
    }
}
.sd-hugs-buttons-group {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    margin-top: 20px;
    flex-wrap: wrap;
    & a {
        text-decoration: none;
        & >button.button {
            background: linear-gradient(180deg, #990000 0%, rgba(153, 0, 0, 0.68) 100%);
            box-shadow: 0px 2px 5px #000000cf;
            border-radius: 40px;
            max-width: 200px;
            width: 100%;
            font-size: 14px;
            letter-spacing: -0.387234px;
            position: relative;
            padding: 10px 15px;
            svg{
                margin-right: 5px;
                color: #fff;
            }
        }
    }
    .tabbed-button{
        &>button+button{
            margin-left:20px;
        }
        button{
            background: #E6CBB0;
            border-radius: 15px 15px 0px 0px;
            width: 140px;
            height: 55px;
            border: none;
            font-weight: 700;
            line-height: 19px;
            text-align: center;
            letter-spacing: -0.387234px;
            color: #990000;
            text-transform: capitalize;
            .label{
                font-family: 'Roboto', sans-serif;
                max-width: 96px;
                font-size: 14px;
            }
            &.active {
                background: #990000;
                color: #fff;
            }
        }
    }
}
.sd-hugs-filter-buttons {
    display: flex;
    align-items: center;
    column-gap: 20px;
}
.sd-hugs-layout-types {
    display: flex;
    align-items: center;
    column-gap: 12px;
    & span {
        display: flex;
        align-items: center;
        cursor: pointer;
        flex-shrink: 0;
        &.active {
            font-weight: bold;
        }
    }
    & .tile-icon {
        border-right: 1px solid;
        padding-right: 12px;
    }
    & img {
        cursor: pointer;
        max-height: 24px;
        display: block;
        margin-right: 5px;
    }
}
.sd-hugstable-container {
    overflow-y: auto;
    & .rt-tbody {
        min-width: max-content !important;
        overflow: hidden;
    }
}
.hugsTable {
    & .sd-hug-item {
        width: 100% !important;
        margin-bottom: 0px;
        & .sd-hug-options {
            display: none;
        }
        & img {
            height: 80px !important;
        }
        & .play-icon-circle {
            height: 30px;
            width: 30px;
            & svg {
                font-size: 24px;
            }
        }
    }
    & .rt-table {
        & .rt-tr {
            background-color: transparent !important;
            & .rt-th {
                & div {
                    font-weight: 700;
                }
            }
        }
    }
    & .rt-thead {
        & .rt-th {
            padding-top: 0px;
            padding-bottom: 0px;
        }
    }
    & .hd_video {
        width: 130px !important;
        flex: none !important;
    }
    & .hd_views,
    & .hd_edit,
    & .hd_share{
        width: 60px !important;
        flex: none !important;
    }
    & .hd_reminder {
        width: 80px !important;
        flex: none !important;
    }
    & .hd_date,
    & .hd_hug-delivery {
        width: 150px !important;
        flex: none !important;
        & p {
            margin-bottom: 5px;
            font-size: 13px;
        }
        & button {
            padding: 5px 8px;
            text-transform: none;
            font-size: 13px;
            text-transform: none;
        }
    }
    & .hd_to {
        width: 200px !important;
        flex: none !important;
    }
    & .hd_greeting {
        & p {
            cursor: pointer;
            margin-bottom: 0px;
        }
    }
    & svg{
        color: #fff;
        display: block;
        font-size: 28px;
        background-color: #990000;
        border-radius: 5px;
        padding: 3px;
        cursor: pointer;
    }
    & .st-btn {
        display: none !important;
        &.st-last {
            height: 28px !important;
            display: inline-block !important;
            top: 0px !important;
            background-color: #990000 !important;
            padding: 3px !important;
            & img {
                width: 100% !important;
                height: 100% !important;
                top: 0px !important;
            }
        }
    }
}
.sd-load-more {
    text-align: center;
    height: 70px;
    display: flex;
    align-items: center;
    justify-content: center;
}
@media only screen and (max-width: 991px){
    .sd-hugs-items-container {
        & .sd-hug-item{
            width: 33%;
        }
        .sd-hug-content{
            & img {
                height: 200px;
            }}
    }
    .sd-home-welcome-note {
        h4{
            font-size: 18px;
        }
        p{
            font-size: 16px;
        }
    }
    .sd-hugs-buttons-group{
        .tabbed-button{
            &>button+button{
                margin-left: 15px;
            }
        }
    }
    .sd-hug-content {
        .sd-hug-pending-content {
            height: 200px;
        }
    }
}
@media only screen and (max-width: 767px){
    .sd-home-welcome-note {
        p{
            font-size: 15px;
        }
    }
    .sd-hugs-buttons-group{
        justify-content: center;
        row-gap: 15px;
        margin-top: 15px;
        flex-direction: column;
        align-items: center;
        .tabbed-button {
            text-align: center;
        }
        &>button{
            font-size: 15px;
            width: 200px;
        }
    }
    .sd-hugs-items-container {
        column-gap: 1%;
        & .sd-hug-item{
            width: 49.5%;
            margin-bottom: 6px;
        }
        &.list-items {
            & .sd-hug-list-item {
                column-gap: 15px;
                & .sd-hug-item {
                    max-width: 250px;
                    & img {
                        height: 150px;
                    }
                }
            }
            & .sd-hug-item-detail {
                & p {
                    margin-bottom: 15px;
                }
            }
        }
    }
}
@media only screen and (max-width: 620px){
    .sd-hugs-items-container {
        &.list-items {
            row-gap: 30px;
            & .sd-hug-list-item {
                column-gap: 15px;
                flex-direction: column;
                row-gap: 10px;
                & .sd-hug-item {
                    max-width: 100%;
                    & img {
                        height: 250px;
                    }
                }
            }
        }
    }
    .sd-hugs-layout-types {
        column-gap: 10px;
    }
    .sd-hugs-buttons-group{
        .tabbed-button{
            &>button+button{
                margin-left: 10px;
            }
        }
    }
    .sd-hugs-filter-buttons {
        flex-direction: column;
        row-gap: 10px;
    }
}
@media only screen and (max-width: 480px){
    .sd-hugs-items-container {
        .sd-hug-item{
            width: 100%;
        }
        &.list-items {
            & .sd-hug-list-item {
                & .sd-hug-item {
                    & img {
                        height: 200px;
                    }
                }
            }
        }
    }
    .sd-hugs-buttons-group {
        &>button{
            letter-spacing: normal;
            font-size: 14px;
        }
        .tabbed-button {
            button{
                width: 135px;
            }
        }
    }
}