.sd-user-profile-settings-container {
    * {
        font-family: 'Roboto', sans-serif;
    }
}
.sd-user-cover-container{
    position: relative;
    height: 320px;
    img{
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
    .sd_loader {
        position: absolute;
        width: 100%;
        height: 100%;
        background-color: #ffffff80;
    }
}
.sd-cover-edit {
    position: absolute;
    top: 40px;
    text-align: right;
    left: 0;
    right: 0;
    margin: auto;
    color: #fff;
    .sd-change-cover-photo {
        display: inline-block;
        cursor: pointer;
        svg{
            font-size: 30px;
            color: #fff;
        }
    }
}
.sd-user-profile-info {
    display: flex;
    align-items: flex-end;
    position: relative;
    top: -85px;
    max-width: 1100px;
    img{
        width: 100%;
        height: 100%;
        object-fit: cover;
        display: block;
    }
    .sd-username h2{
        margin-bottom: 0;
        margin-left: 5px;
    }
    .sd-user-profile-icon {
        position: relative;
        border: 3px solid #D6A87B;
        width: 170px;
        height: 170px;
        border-radius: 100%;
        background-color: #FFFFFF;
        display: flex;
        align-items: center;
        justify-content: center;
        overflow: hidden;
        &:hover .sd-change-profile-photo {
            display: flex;
        }
    }
    .sd-change-profile-photo {
        display: none;
        position: absolute;
        top: 0px;
        left: 0px;
        width: 100%;
        height: 100%;
        align-items: center;
        justify-content: center;
        z-index: 111;
        background-color: #0000004d;
        border-radius: 100%;
        cursor: pointer;
        svg {
            background-color: #fff;
            width: 50px;
            height: 50px;
            padding: 10px;
            border-radius: 100%;
        }
    }
}
.sd-settins-form-wrapper {
    margin-top: -30px;
    padding-bottom: 80px;
}
.sd-settins-form-container {
    background: #EFDCCA;
    border-radius: 8px;
    padding: 30px;
    margin-bottom: 50px;
    h3{
        letter-spacing: -0.387234px;
        text-transform: uppercase;
        margin-bottom: 20px;
        margin-top: -4px;
    }
    .sd-error-messages {
        margin-bottom: 20px;
    }
    .elements-container {
        position: relative;
    }
    .elements-wrapper{
        margin-bottom: 20px;
        &:last-child{
            margin-bottom: 0;
        }
        position: relative;
        label{
            display: none;
        }
        input {
            background: #FFFFFF;
            border: 1px solid #D9D9D9;
            border-radius: 6px;
            width: 100%;
            padding: 16px 17px;
            line-height: 16px;
            outline: none;
            &.error {
                border-color: red;
            }
        }
        svg {
            position: absolute;
            right: 3px;
            top: 19px;
            font-size: 22px;
            width: auto !important;
            fill: red;
        }
    }
    .row-elements {
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-wrap: wrap;
        column-gap: 50px;
        &>div{
            flex:1;
        }
    }
}
.sd-settings-buttons-wrapper {
    display: flex;
    justify-content: flex-end;
    column-gap: 20px;
    button{
        max-width: 150px;
        width: 100%;
        & svg {
            color: #fff;
        }
    }
}
@media only screen and (max-width: 1199px){
    .sd-user-cover-container{
        height: 300px;
    }
    .sd-user-profile-info{
        top: -80px;
        .sd-user-profile-icon {
            height: 160px;
            width: 160px;
        }
    }
    .sd-settins-form-wrapper{
        padding-bottom: 50px;
    }
    .sd-settins-form-container{
        margin-bottom: 50px;
    }
}
@media only screen and (max-width: 991px){
    .sd-user-cover-container{
        height: 250px;
    }
    .sd-user-profile-info{
        top: -75px;
        .sd-user-profile-icon {
            height: 150px;
            width: 150px;
        }
    }
    .sd-settins-form-wrapper{
        padding-bottom: 30px;
    }
    .sd-settins-form-container{
        padding: 20px;
        margin-bottom: 30px;
        .elements-wrapper {
            margin-bottom: 20px;
            column-gap: 20px;
            svg {
                top: 15px;
                font-size: 18px;
            }
        }
    }
}
@media only screen and (max-width: 767px){
    .sd-cover-edit{
        top: 25px;
        .sd-change-cover-photo {
            svg{
                font-size: 25px;
            }
        }
    }
    .sd-user-cover-container{
        height: 200px;
    }
    .sd-user-profile-info{
        top: -65px;
        .sd-user-profile-icon {
            height: 130px;
            width: 130px;
        }
    }
    .sd-settins-form-wrapper{
        padding-bottom: 20px;
    }
    .sd-settins-form-container{
        margin-bottom: 20px;
        .elements-wrapper {
            margin-bottom: 15px;
            column-gap: 15px;
        }
    }
}
@media only screen and (max-width: 480px){
    .sd-settins-form-wrapper{
        margin-top: -20px;
    }
    .sd-cover-edit{
        top: 20px;
    }
    .sd-user-cover-container{
        height: 130px;
    }
    .sd-user-profile-info{
        top: -50px;
        .sd-user-profile-icon {
            height: 100px;
            width: 100px;
        }
    }
    .sd-settins-form-container {
        padding: 15px;
        .elements-wrapper {
            margin-bottom: 5px;
        }
        .row-elements {
            &>div {
                flex: auto;
                &:first-child {
                    margin-right: 0;
                    margin-bottom: 5px;
                }
            }
        }
    }
    .sd-settings-buttons-wrapper {
        column-gap: 10px;
    }
}