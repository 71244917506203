.sd-step3-note {
    margin-bottom: 30px;
    & h3{
        margin-bottom: 10px;
    }
    & p {
        letter-spacing: -0.387234px;
        margin-bottom: 0px;
    }
}
.sd-step-three-icon {
    & .sd-icon {
        background: linear-gradient(233.87deg, #FFFFFF 2.19%, rgba(255, 255, 255, 0.19) 99.96%);
        box-shadow: 0px 0px 10px #00000040;
        border-radius: 8px;
        width: 80px;
        height: 80px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        margin: 30px auto 12px;
        & svg {
            font-size: 40px;
        }
    }
    & p {
        font-weight: 700;
        font-size: 16px;
        text-align: center;
        letter-spacing: -0.387234px;
        margin-bottom: 0px;
    }
}
.sd-step-three-photos-list {
    background: #FFFFFF;
    border-radius: 8px;
    padding: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    column-gap: 20px;
    margin-top: 20px;
    list-style-type: none;
    & .sd-step-three-photo {
        border: 3px solid;
        border-radius: 8px;
        overflow: hidden;
        width: 150px;
        height: 130px;
        position: relative;
        cursor: move;
        & img {
            display: block;
            object-fit: cover;
            height: 100%;
            width: 100%;
            padding: 2px;
            border-radius: 8px;
        }
        & .move-icon {
            position: absolute;
            top: 8px;
            left: 8px;
            & svg {
                color: #fff;
                font-size: 30px;
                background-color: #990000;
                border-radius: 5px;
                padding: 3px;
                display: block;
            }
        }
        & .delete-icon {
            position: absolute;
            bottom: 8px;
            right: 8px;
            cursor: pointer;
            & svg {
                color: #fff;
                font-size: 30px;
                background-color: #990000;
                border-radius: 5px;
                padding: 3px;
                display: block;
            }
        }
        & .sd_loader {
            position: absolute;
            top: 0px;
            bottom: 0px;
            margin: auto;
            left: 0px;
            right: 0px;
        }
    }
}
.moveable-photos { 
    display: none;
    &>div {
        display: flex;
        column-gap: 30px;
        row-gap: 30px;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
    }
}

@media only screen and (max-width: 991px){
    .sd-step-three-photos-list {
        row-gap: 15px;
        & .sd-step-three-photo {
            & .move-icon {
                & svg {
                    font-size: 25px;
                }
            }
            & .delete-icon {
                & svg {
                    font-size: 25px;
                }
            }
        }
    }
}

@media only screen and (max-width: 480px){
    .sd-step-three-photos-list {
        row-gap: 10px;
        column-gap: 10px;
        & .sd-step-three-photo {
            width: 200px;
            height: 170px;
        }
    }
}