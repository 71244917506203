.hug-process-bar {
    position: fixed;
    bottom: 0px;
    color: #fff;
    background-color: #fff;
    width: 100%;
    z-index: 1;
    box-shadow: 0px 11px 15px -7px rgba(0,0,0,0.2), 0px 24px 38px 3px rgba(0,0,0,0.14), 0px 9px 46px 8px rgba(0,0,0,0.12);
    & .container {
        position: relative;
        display: flex;
        align-items: center;
        height: 64px;
        column-gap: 10px;
        justify-content: space-between;
        & >div {
            display: flex;
            align-items: center;
            column-gap: 10px;
        }
    }
    & p {
        margin-bottom: 0px;
    }
    & .progressbar-close {
        background-color: #990000 !important;
        & svg {
            color: #fff;
            fill: #fff;
        }
    }
    & a {
        text-decoration: none;
    }
}