.sd-invite-section{
    padding: 40px 0;
    & *{
        font-family: 'Roboto', sans-serif;
    }
    & h3{
        margin-bottom: 15px;
    }
    & form{
        margin-top: 30px;
        & textarea{
            padding: 25px;
            width: 100%;
            min-height: 165px;
            line-height: normal;
            overflow: hidden;
            align-items: center;
            background: rgba(153, 0, 0, 0.05);
            border: 1px solid;
            border-radius: 20px;
            outline: none;
            display: block;
        }
        & button {
            max-width: 100%;
            width: 100%;
            margin-top: 35px;
            & .sd_loader {
                & svg {
                    color: #fff;
                }
            }
        }
    }
}