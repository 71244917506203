.sd-record-audio-modal {
    & * {
        font-family: 'Roboto', sans-serif;
    }
    & .modal-header {
        text-align: right;
        & button {
            padding: 0px;
            box-shadow: none;
            background: transparent;
            position: absolute;
            right: 15px;
            top: 15px;
            & svg {
                font-size: 34px;
            }
        }
    }
    & .modal-title {
        font-weight: 700;
        font-size: 29px;
        line-height: 34px;
        letter-spacing: -0.387234px;
        margin-bottom: 23px;
        text-align: left;
    }
    & .MuiPaper-root {
        max-width: 750px;
        width: 100%;
        padding: 50px 20px 40px;
        display: block;
        border-radius: 8px;
    }
    & .MuiDialogTitle-root,
    & .MuiDialogContent-root,
    & .MuiDialogActions-root {
        padding: 0px;
        overflow: hidden;
    }
    & .modal-footer {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 10px;
        column-gap: 10px;
        max-width: 624px;
        margin-left: auto;
        margin-right: auto;
        & button.button {
            max-width: 150px;
            width: 100%;
            padding: 12px 15px;
            margin: 0px;
        }
        & .sd-audio-player-actions {
            display: flex;
            justify-content: center;
            align-items: center;
            column-gap: 25px;
            & svg, & span {
                cursor: pointer;
                font-size: 50px;
                display: block;
                &.record-button {
                    border: 4px solid #990000;
                    border-radius: 100%;
                    background-color: #990000;
                    color: #fff;
                    font-size: 60px;   
                }
            }
            & span {
                width: 60px;
                height: 60px;
                display: flex;
                align-items: center;
                justify-content: center;
                &.record-button {
                    font-size: 40px;
                }
            }
            & .mic-icon {
                &.recording {
                    & .record-button {
                        border-color: green;
                        background-color: green;
                    }
                }
            }
            & a {
                text-decoration: none;
                color: #990000;
            }
        }
    }
    & .sd-record-audio-content {
        text-align: center;
        & svg {
            font-size: 80px;
        }
        & .sd-record-audio-player {
            position: relative;
            max-width: 624px;
            width: 100%;
            margin: 0px auto;
            padding-top: 25px;
            & .sd-audio-player-bar {
                background: rgba(214, 168, 123, 0.32);
                width: 100%;
                height: 11px;
            }
            & .sd-audio-player-circle {
                background: #990000;
                width: 26px;
                height: 26px;
                border-radius: 100%;
                left: 0%;
                top: 17px;
                position: absolute;
                transition: all 0.3s ease;
            }
            & .sd-audio-time {
                display: flex;
                align-items: center;
                justify-content: space-between;
                margin-top: 10px;
                & p {
                    font-size: 20px;
                    line-height: 23px;
                    text-align: center;
                    letter-spacing: -0.387234px;
                    margin-bottom: 0px;
                }
            }
        }
    }
}

@media only screen and (max-width: 991px){
    .sd-record-audio-modal {
        & .modal-title {
            font-size: 24px;
            line-height: 30px;
        }
        // & .modal-footer {
        //     & .sd-audio-player-actions {
        //         column-gap: 10px;
        //         & svg {
        //             font-size: 40px;
        //         }
        //     }
        // }
    }
}
@media only screen and (max-width: 767px){
    .sd-record-audio-modal {
        & .sd-record-audio-content {
            // & svg {
            //     font-size: 60px;
            // }
            & .sd-record-audio-player {
                & .sd-audio-player-bar {
                    height: 10px;
                }
                & .sd-audio-player-circle {
                    width: 20px;
                    height: 20px;
                    top: 20px;
                }
                & .sd-audio-time {
                    & p {
                        font-size: 18px;
                        line-height: 20px;
                    }
                }
            }
        }
        // & .modal-footer {
        //     column-gap: 5px;   
        //     & .sd-audio-player-actions {
        //         column-gap: 0px;
        //         & svg {
        //             font-size: 35px;
        //         }
        //     }
        // }
    }
}
@media only screen and (max-width: 480px){
    .sd-record-audio-modal {
        & .modal-footer {
            & button.button {
                font-size: 16px;
                line-height: 20px;
            }   
            // & .sd-audio-player-actions {
            //     & svg {
            //         font-size: 40px;
            //     }
            // }
        }
    }
}