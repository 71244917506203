.brand {
    min-width: 200px;
}
.brand img {
    max-height: 51px;
    display: block;
}
header.appbar {
    display: flex;
    border: 0;
    padding-top: 15px;
    padding-bottom: 15px;
    width: 100%;
    background-color: #fff;
    position: relative;
    box-shadow: none;
    padding-right: 0px !important;
    border-bottom: 2px solid #990000;
    &.loggedin {
        & .sd-header-content {
            & .sd-left-menu {
                display: none;
            }
        }
    }
    div .list {
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -ms-flex-direction: row;
        flex-direction: row;
        margin-top: 0px;
        display: flex;
        padding-left: 0;
        margin-bottom: 0;
        list-style: none;
        padding: 0;
        & .listItem {
            color: inherit;
            position: relative;
            display: block;
            width: auto;
            margin: 0;
            padding: 0;
            margin-right: 30px;
            &:nth-last-child(2) {
                margin-right: 20px;
            }
            &:last-child {
                margin-right: 0px;
            }
            &.dashboard-menu {
                margin-right: 0px;
            }
            & .navLink {
                position: relative;
                padding: 10px 0px;
                line-height: 24px;
                text-decoration: none;
                margin: 0px;
                display: inline-flex;
                align-items: center;
                color: #990000;
                text-transform: uppercase;
                font-size: 16px;
                letter-spacing: -0.387234px;
                font-weight: bold;
            }
            & .navButton {
                text-decoration: none;
                & button.button{
                    text-transform: none;
                    font-weight: 400;
                }
            }
        }
    }
    .listItemText {
        padding: 0 !important;
    }
    &.loggedin {
        padding-top: 10px;
        padding-bottom: 10px;
        & * {
            font-family: 'Roboto', sans-serif;
        }
        & .brand {
            margin-right: 77px;
        }
        & .list {
            & .listItem {
                & .navLink {
                    &.about-us {
                        margin-right: 30px;
                    }
                }
            }
        }
    }
    & .container {
        justify-content: space-between;
        min-height: auto;
    }
}
.sd-header-content,
.sd-authorized-menu {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex: 1;
}
.absolute {
    position: absolute;
    top: auto;
}
.fixed {
    position: fixed;
}
.appResponsive {
    margin: 20px 10px;
    margin-top: 0px;
}
.transparent {
    background-color: transparent !important;
    box-shadow: none;
    padding-top: 25px;
    color: #fff;
}
.MuiDrawer-paper {
    border: none;
    bottom: 0;
    width: 260px;
    position: fixed;
    display: block;
    top: 0;
    height: 100vh;
    right: 0;
    left: auto;
    visibility: visible;
    overflow-y: visible;
    border-top: none;
    text-align: left;
    padding-right: 0px;
    padding-left: 0;
}
// .hidden {
//     width: 100%;
// }
.collapse {
    display: flex !important;
    -ms-flex-preferred-size: auto;
    flex-basis: auto;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center
}
button.closeButtonDrawer {
    position: absolute;
    right: 8px;
    top: 9px;
    z-index: 1
}
.sd-search-form {
    max-width: 325px;
    width: 100%;
    margin-right: 50px;
    display: none;
}
.sd-search-bar {
    background: #990000;
    border-radius: 35px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    overflow: hidden;
    & svg {
        font-size: 25px;
        color: #fff;
        margin-left: 12px;
    }
    & input{
        background: transparent;
        border: none;
        color: #fff;
        font-size: 16px;
        line-height: normal;
        padding: 13px 15px 13px 0px;
        outline: none;
        width: 100%;
        &:-webkit-autofill {
            -webkit-box-shadow: 0 0 0 30px #990000 inset;
            -webkit-text-fill-color: #fff;
        }
    }
    .sd-mobile-search{
        display: none;
    }
}
.sd-notifications-section {
    margin-right: 30px;
    & svg {
        cursor: pointer;
        font-size: 35px;
        display: block;
    }
}
.sd-notifications-dropdown {
    & * {
        font-family: 'Roboto', sans-serif;
    }
    & .MuiPaper-root {
        border: 1px solid #C4C4C4;
        box-shadow: 2px 4px 4px #00000040;
        border-radius: 0px 0px 15px 15px;
        max-width: 314px;
        width: 100%;
        padding: 20px;
        top: 73px !important;
        max-height: calc(100vh - 300px);
        & h6 {
            font-weight: bold;
            font-size: 16px;
            letter-spacing: -0.387234px;
            margin-bottom: 7px;
            padding-bottom: 8px;
            border-bottom: 1px solid;
        }
        & ul {
            text-decoration: none;
            list-style-type: none;
            & li {
                & p {
                    margin: 0px;
                    font-size: 16px;
                    line-height: 23px;
                    letter-spacing: -0.387234px;
                    cursor: pointer;
                    & a {
                        color: #990000;
                        text-decoration: none;
                    }
                }
                &+li {
                    border-top: 1px solid;
                    padding-top: 7px;
                    margin-top: 7px;
                }
            }
        }
    }
}
.sd-user-icon-name {
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 700;
    font-size: 20px;
    line-height: 23px;
    letter-spacing: -0.387234px;
    color: #990000;
    cursor: pointer;
    .sd-user-title {
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        max-width: 165px;
    }
    & button {
        padding: 0px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #990000;
        border-radius: 100%;
        height: 50px;
        width: 50px;
        margin-right: 10px;
        color: #fff;
        overflow: hidden;
        &:hover {
            background-color: #990000;
        }
        & img {
            width: 100%;
            height: 100%;
            display: block;
        }
    }
}
.sd-profile-menu {
    & * {
        font-family: 'Roboto', sans-serif;
    }
    & .MuiPaper-root {
        border: 1px solid #C4C4C4;
        box-shadow: 2px 4px 4px #00000040;
        border-radius: 0px 0px 15px 15px;
        max-width: 314px;
        width: 100%;
        padding: 20px;
        top: 73px !important;
        max-height: calc(100vh - 300px);
        & h6 {
            font-weight: 900;
            font-size: 16px;
            letter-spacing: -0.387234px;
            margin-bottom: 7px;
            padding-bottom: 8px;
            border-bottom: 1px solid;
        }
        & .MuiMenuItem-root {
            background-color: transparent;
            padding-left: 0px;
            padding-right: 0px;
            font-size: 16px;
            letter-spacing: -0.387234px;
            padding-top: 3px;
            padding-bottom: 3px;
            color: #990000;
            min-height: auto;
            & button.button {
                width: 100%;
                padding-top: 12px;
                padding-bottom: 12px;
                & svg {
                    margin-right: 13px;
                    color: #fff;
                }
            }
        }
        & .MuiDivider-root{
            border-width: 2px;
            border-color: #990000;
            margin-top: 40px;
        }
    }
}
.collapse {
    display: flex !important;
    -ms-flex-preferred-size: auto;
    flex-basis: auto;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center
}
.mlAuto {
    margin-left: auto !important;
}
.sd-mobile-search{
    display: none;
    color: #990000;
    svg{
        font-size: 30px;
        display: block;
    }
}
.sd-menu-drawer{
    .MuiListItem-root{
        padding: 0px;
        .navLink{
            color: #900;
            font-size: 18px;
            font-weight: 400;
            line-height: 24px;
            padding: 10px !important;
            text-decoration: none;
        }
        .navButton{
            padding: 10px !important;
            text-decoration: none;
        }
    }
}
.sd-active-search {
    overflow: hidden;
    &::before{
        content: "";
        background-color: rgba(0, 0, 0, 0.5);
        height: 100%;
        width: 100%;
        position: absolute;
        z-index: 9;
    }
}
@media only screen and (max-width: 1199px){
    header.appbar.loggedin .brand {
        margin-right: 40px;
    }
    .sd-search-form {
        max-width: 300px;
        margin-right: 30px;
    }
    .sd-notifications-section {
        margin-right: 30px;
    }
    .sd-user-icon-name button{
        height: 50px;
        width: 50px;
    }
}
@media only screen and (max-width: 1092px){
    .sd-search-form{
        max-width: 250px;
    }
}
@media only screen and (max-width: 899px){
    header.appbar {
        padding-top: 10px;
        padding-bottom: 10px;
        &.loggedin {
            & .brand {
                margin-right: 20px;
            }
            & .sd-header-content {
                & .sd-left-menu {
                    display: block;
                }
            }
        }
    }
    div .list {
        display: block;
        width: 100%;
        & .listItem {
            display: block;
            width: 100%;
            margin: 0px;
            & .navLink,
            & .navButton {
                display: block;
                width: 100%;
                padding: 1rem;
                & button.button {
                    max-width: 150px;
                    width: 100%;
                }
            }
        }
    }
    .sd-search-form {
        max-width: none;
        width: auto;
        margin-right: 10px;
    }
    .sd-search-bar{
       display: none;
        svg {
            font-size: 28px;
            margin-left: 10px;
        }
        input {
            font-size: 16px;
        }
       &.active {
            display: flex;
            margin-right: 0;
            position: fixed;
            top: 99px;
            width: 100%;
            left: 0;
            right: 0;
            max-width: 500px;
            margin: auto;
       }
    }
    .sd-mobile-search {
        display: block;
    }
    .sd-notifications-section {
        margin-right: 15px;
    }
    .sd-header-content {
        button{
            color:#990000;
        }
    }
    .sd-user-icon-name {
        font-size: 18px;
        button{
            height: 40px;
            width: 40px;
        }
    }
    .sd-notifications-section svg{
        font-size: 30px;
    }
    .sd-profile-menu{
        .MuiPaper-root{
            padding: 15px 20px;
            max-height: calc(100vh - 200px);
        } 
    }
    .sd-notifications-dropdown{
        .MuiPaper-root{
            max-height: calc(100vh - 200px);
        }
    }
    .sd-user-icon-name{
        .sd-user-title {
            max-width: 100px;
        }
    }
}
@media only screen and (max-width: 600px){
    header.appbar.loggedin{
        .brand{
            margin-right: 10px;
            min-width: 0;
            img{
                max-width: 120px;
            }
        }
    } 
    .sd-user-icon-name button{
        height: 35px;
        width: 35px;
    }
    .sd-notifications-section svg,
    .sd-mobile-search svg{
        font-size: 25px;
    }
    .sd-user-icon-name {
        font-size: 16px;
    }
    .active.sd-search-bar{
        top: 62px;
    }
    .sd-profile-menu{
        .MuiPaper-root{
            top:62px !important;
            padding: 10px 15px;
            .MuiDivider-root {
                margin-top: 20px;
            }
        } 
    }
    .sd-notifications-dropdown{
        .MuiPaper-root{
            padding: 15px 20px;
            top: 62px !important;
            h6{
                margin-bottom: 8px;
            }
        }
    }
}
@media only screen and (max-width: 480px){
    header.appbar {
        &.loggedin {
            .brand{
                img{
                    max-width: 80px;
                }
            }
        }
        div .list {
            .listItem {
                .navLink {
                    text-transform: capitalize;
                }
            }
        }
    } 
    .sd-user-icon-name{
        font-size: 14px;
        .sd-user-title {
            max-width: 100px;
        }
        button{
            height: 25px;
            width: 25px;
            margin-right: 5px;
        }
    }
    .sd-search-form{
        margin-right: 5px;
        svg{
            font-size: 25px;
        }
    } 
    .sd-notifications-section {
        margin-right: 5px;
    }
    .active.sd-search-bar {
        top: 55px;
        max-width: 97%;
    }
    .sd-header-content button.mobile-menu-toggle{
        padding-right: 0;
        padding-left: 5px;
    }
}
@media only screen and (max-width: 400px){
    .sd-user-icon-name{
        .sd-user-title {
            max-width: 70px;
        }
    }
}